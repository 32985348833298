import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Events, NavParams, ModalController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { Storage } from '@ionic/storage';
import { NewAddressPage } from "../new-address/new-address.page";
import { UtilsService } from '../services/utils/utils.service';
import { SharedService } from '../services/shared/shared.service';
export class SelectAddressPage {
    constructor(events, router, storage, navParams, UtilsService, modalController, activatedRoute, sharedService) {
        this.events = events;
        this.router = router;
        this.storage = storage;
        this.navParams = navParams;
        this.UtilsService = UtilsService;
        this.modalController = modalController;
        this.activatedRoute = activatedRoute;
        this.sharedService = sharedService;
        this.addressesData = [];
        this.showLoader = true;
        this.showAddAddressBtn = false;
        this.addressType = 'shipping';
        this.pageType = 'page';
        if (this.pageType === 'page') {
            this.addressType = navParams.get('type');
        }
    }
    ngOnInit() {
        this.initializeSubscription();
        this.events.publish('user:getAllSavedAddresses');
        setTimeout(() => {
            this.showAddAddressBtn = true;
        }, 500);
    }
    ionViewWillEnter() {
        this.storage.get('userDefaultAddress').then((address) => {
            this.addressFromStorage = address;
        });
    }
    ngOnDestroy() {
        this.removeSubscription();
    }
    initializeSubscription() {
        this.events.subscribe('user:publishAllSavedAddresses', (allAddresses) => {
            this.addressesData = allAddresses;
            this.UtilsService.consoleLog('address modal address', this.addressesData);
            this.showLoader = false;
        });
    }
    /*radioSelect(event) {
      //// // console.log(event.target.value);
      this.selectedAddress = event.target.value;
    }*/
    modalDismiss() {
        this.modalController.dismiss();
    }
    selectAddress(address) {
        this.selectedAddress = address;
    }
    goToPage(page) {
        if (this.pageType === 'modal') {
            this.openNewAddressModal();
        }
        else {
            const navigationExtras = {
                state: {
                    routeFromSelectAddress: true,
                    type: this.addressType,
                }
            };
            this.router.navigate([page], navigationExtras);
        }
    }
    addNewAddress() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: NewAddressPage,
                cssClass: 'custom-modal',
                componentProps: {
                    routeFromSelectAddress: true,
                    type: this.addressType
                }
            });
            yield modal.present();
        });
    }
    editAddress(address) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.pageType === 'modal') {
                this.openNewAddressModal(address);
            }
            else {
                const modal = yield this.modalController.create({
                    component: NewAddressPage,
                    cssClass: 'custom-modal',
                    componentProps: {
                        addressData: address,
                        addressLength: this.addressesData.length,
                        routeFromSelectAddress: true,
                        type: this.addressType
                    }
                });
                yield modal.present();
            }
        });
    }
    closeModal() {
        this.modalController.dismiss({ closed: true });
    }
    onClickDeliverHere() {
        if (!this.selectedAddress) {
            if (this.addressesData[0]) {
                this.selectedAddress = this.addressesData[0];
            }
            else {
                this.sharedService.presentAlert('Please add address');
                return;
            }
        }
        if (this.addressType === 'shipping') {
            this.storage.set('userDefaultAddress', this.selectedAddress);
        }
        else {
            this.storage.set('userBillingAddress', this.selectedAddress);
        }
        if (this.pageType === 'modal') {
            this.modalController.dismiss({ closed: false });
        }
        else {
            this.router.navigate(['order-summary']);
        }
    }
    removeSubscription() {
        this.events.unsubscribe('user:publishAllSavedAddresses');
    }
    openNewAddressModal(address = null) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const modal = yield this.modalController.create({
                component: NewAddressPage,
                componentProps: {
                    pageType: 'modal',
                    editAddressData: address,
                    addressLength: this.addressesData.length,
                }
            });
            modal.onDidDismiss().then((res) => {
                if (res && res.data && !res.data.closed) {
                    this.events.publish('user:getAllSavedAddresses');
                }
            });
            yield modal.present();
        });
    }
}
