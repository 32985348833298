import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, first } from 'rxjs/operators';
import * as firebase from 'firebase';
import { firestore } from 'firebase';
import { UserService } from '../user/user.service';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../user/user.service";
import * as i4 from "@ionic/storage";
export class CouponCodesService {
    constructor(events, afs, userService, storage) {
        this.events = events;
        this.afs = afs;
        this.userService = userService;
        this.storage = storage;
    }
    initializeSubscriptions() {
        this.events.subscribe('coupon-codes:getAllCouponCodes', () => {
            this.getAllCouponCodes();
        });
        this.events.subscribe('coupon-codes:addNewCouponCode', (data) => {
            this.addNewCouponCode(data);
        });
        this.events.subscribe('coupon-codes:editCouponCode', (id, data) => {
            this.editCouponCode(id, data);
        });
        this.events.subscribe('coupon-codes:deleteCouponCode', (id) => {
            this.deleteCouponCode(id);
        });
        this.events.subscribe('coupon-codes:deleteEditCouponCode', (id) => {
            this.deleteEditCouponCode(id);
        });
        this.events.subscribe('coupon-codes:addProductAsNotApplicable', (product, codeId) => {
            this.addProductAsNotApplicable(product, codeId);
        });
        this.events.subscribe('coupon-codes:removeNotApplicableProduct', (product, codeId) => {
            this.removeNotApplicableProduct(product, codeId);
        });
        this.events.subscribe('coupon-codes:verifyCouponCode', (data) => {
            this.verifyCouponCode(data);
        });
        this.events.subscribe('coupon-codes:removeSubs', () => {
            if (this.getCouponsSub) {
                this.getCouponsSub.unsubscribe();
            }
        });
    }
    getAllCouponCodes() {
        try {
            const couponsRef = this.afs.collection('features').doc('coupons').collection('codes', ref => ref
                .orderBy('createdAt', 'desc'));
            const couponsSnap = couponsRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return Object.assign({ id }, data);
            })));
            this.getCouponsSub = couponsSnap.subscribe((res) => {
                if (!res.length) {
                    this.events.publish('coupon-codes:noCouponCodes');
                }
                else {
                    this.events.publish('coupon-codes:publishAllCouponCodes', res);
                }
            });
        }
        catch (error) {
            console.dir(error);
        }
    }
    addNewCouponCode(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const couponCode = yield this.afs.collection('features').doc('coupons').collection('codes', ref => ref
                    .where('name', '==', data.name)).valueChanges().pipe(first()).toPromise();
                if (couponCode && couponCode.length > 0) {
                    this.events.publish('coupon-codes:couponCodeAlreadyExists', data.name);
                }
                else {
                    data["createdAt"] = firebase.firestore.FieldValue.serverTimestamp();
                    yield this.afs.collection('features').doc('coupons').collection('codes').add(data);
                    this.events.publish('coupon-codes:addNewCouponCodeSuccess');
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    editCouponCode(id, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('features').doc('coupons').collection('codes').doc(id).update(data);
                this.events.publish('coupon-codes:editCouponCodeSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    deleteCouponCode(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('features').doc('coupons').collection('codes').doc(id).delete();
                this.events.publish('coupon-codes:deleteCouponCodeSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    deleteEditCouponCode(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('features').doc('coupons').collection('codes').doc(id).delete();
                this.events.publish('coupon-codes:deleteEditCouponCodeSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    addProductAsNotApplicable(product, codeId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('features').doc('coupons').collection('codes').doc(codeId).update({
                    notApplicableProducts: firestore.FieldValue.arrayUnion(product)
                });
                this.events.publish('coupon-codes:addProductAsNotApplicableSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    removeNotApplicableProduct(product, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('features').doc('coupons').collection('codes').doc(id).update({
                    notApplicableProducts: product
                });
                this.events.publish('coupon-codes:removeNotApplicableProductSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    verifyCouponCode(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                data['userId'] = this.userService.getUserId();
                let verifyCouponCode = firebase.functions().httpsCallable('orders-verifyCouponCode');
                verifyCouponCode(data).then((response) => {
                    //// // console.log('res for cc', response);
                    if (response && response.data) {
                        if (response.data.success === false) {
                            this.events.publish('coupon-codes:couponCodeNotApplied', response.data.failureMsg);
                        }
                        else {
                            this.events.publish('coupon-codes:couponCodeApplied', response.data);
                        }
                    }
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getCouponCodesForUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const couponData = (yield this.afs.collection('features').doc('coupons').valueChanges().pipe(first()).toPromise()) || { showAllCoupons: true };
                if (Object.keys(couponData).length && !couponData.showAllCoupons) {
                    return [];
                }
                const uid = yield this.storage.get('uid');
                const allCoupons = yield this.afs.collection('features').doc('coupons').collection('codes', ref => ref
                    .orderBy('createdAt', 'desc')).valueChanges().pipe(first()).toPromise();
                const availableCoupons = allCoupons.filter(coupon => {
                    if (moment().isSameOrBefore(moment(coupon.validUpto), 'day') && (coupon.qty - coupon.usage >= 1)) {
                        if (coupon.specificUsers.isAllowed) {
                            if (coupon.specificUsers.users.some(u => u.id === uid)) {
                                return coupon;
                            }
                        }
                        else {
                            return coupon;
                        }
                    }
                });
                // console.log('availableCoupons', availableCoupons);
                return availableCoupons;
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    checkCODAvailabilityOfCoupon(couponId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const coupon = yield this.afs.collection('features').doc('coupons').collection('codes', ref => ref
                        .where(firebase.firestore.FieldPath.documentId(), 'in', [couponId]))
                        .valueChanges().pipe(first()).toPromise();
                    if (coupon && coupon.length) {
                        resolve('codAvailable' in coupon[0] ? coupon[0].codAvailable : true);
                    }
                    else {
                        resolve(true);
                    }
                }
                catch (error) {
                    // console.log(error);
                    resolve(true);
                }
            }));
        });
    }
}
CouponCodesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CouponCodesService_Factory() { return new CouponCodesService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.UserService), i0.ɵɵinject(i4.Storage)); }, token: CouponCodesService, providedIn: "root" });
