import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrderService } from 'src/app/services/order/order.service';
import { get } from 'scriptjs';
import { ConfigService } from 'src/app/services/config/config.service';
export class PaypalPage {
    constructor(modalController, orderService, configService) {
        this.modalController = modalController;
        this.orderService = orderService;
        this.configService = configService;
        let _this = this;
        setTimeout(() => {
            // dynamic load paypal script js
            get(`https://www.paypal.com/sdk/js?client-id=${this.paypalObj.client_id}&currency=${this.configService.environment.currencyCode}`, () => {
                // Render the PayPal button into #paypal-button-container
                window['paypal'].Buttons({
                    // Set up the transaction
                    createOrder: function (data, actions) {
                        return tslib_1.__awaiter(this, void 0, void 0, function* () {
                            // console.log('autoConfirm', _this.autoConfirm);
                            if (_this.autoConfirm) {
                                const { payableAmnt, order } = yield _this.orderService.ac_prepareOrderForPayment(_this.orderData, 'paypal');
                                _this.order = order;
                                _this.payableAmnt = payableAmnt;
                            }
                            else {
                                const payableAmnt = _this.orderData.partialPayment.status ? _this.orderData.partialPayment.online.amount : (_this.orderData.totalAmountToPaid - (_this.orderData.walletAmount + _this.orderData.cashbackAmount));
                                _this.payableAmnt = payableAmnt;
                                _this.order = _this.orderData;
                            }
                            return actions.order.create({
                                purchase_units: [{
                                        amount: {
                                            value: _this.payableAmnt.toString()
                                        }
                                    }]
                            });
                        });
                    },
                    // Finalize the transaction
                    onApprove: function (data, actions) {
                        return actions.order.capture()
                            .then(function (details) {
                            // Show a success message to the buyer
                            // console.log('details', details.id);
                            if (_this.autoConfirm) {
                                _this.orderService.ac_payWithPaypal(_this.order, { id: details.id }, _this.payableAmnt);
                            }
                            else {
                                _this.orderService.payWithPaypal(_this.order, { id: details.id }, _this.payableAmnt);
                            }
                        })
                            .catch(err => {
                            // console.log(err);
                        });
                    }
                }).render('#paypal-button-container');
            });
        }, 500);
    }
    ngOnInit() {
    }
    closeModal() {
        this.modalController.dismiss();
    }
}
