import * as tslib_1 from "tslib";
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/fire/storage";
import * as i4 from "@ionic/storage";
export class MediaService {
    constructor(afs, events, fbStorage, storage) {
        this.afs = afs;
        this.events = events;
        this.fbStorage = fbStorage;
        this.storage = storage;
        this.chatImage = {
            url: null,
            size: null,
            uploadedAt: null,
            userId: null
        };
        this.uid = '';
    }
    initializeSubscriptions() {
        this.events.subscribe('media:addChatImage', (uid, msg, imageResponse) => {
            //// // console.log('in media service 1');
            this.addChatImage(uid, msg, imageResponse);
        });
        this.events.subscribe('media:broadcastMessage', (imageResponse, msg) => {
            //// // console.log('in media service 2');
            this.broadcastMessage(imageResponse, msg);
        });
        this.mediaRef = this.afs.collection('media');
    }
    addChatImage(uid, msg, imageResponse) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const listofImageId = [];
            const listOfDownloadUrls = [];
            let msgRef;
            msg.imageCount = imageResponse.length;
            const lastMsgData = yield this.afs.collection('chats').doc(uid).valueChanges().pipe(first()).toPromise();
            if (msg.author === "user") {
                if (lastMsgData.adminActive === false) {
                    msgRef = yield this.afs.collection('chats').doc(uid).collection('messages').add(msg);
                    this.afs.collection('chats').doc(uid).update({ unreadMsgs: lastMsgData.unreadMsgs + 1 });
                }
                else {
                    msg.isRead = true;
                    msgRef = yield this.afs.collection('chats').doc(uid).collection('messages').add(msg);
                }
            }
            else {
                if (lastMsgData.userActive === false) {
                    msgRef = yield this.afs.collection('chats').doc(uid).collection('messages').add(msg);
                    this.afs.collection('chats').doc(uid).update({ unreadAdminMsgs: lastMsgData.unreadAdminMsgs + 1 });
                }
                else {
                    msg.isRead = true;
                    msgRef = yield this.afs.collection('chats').doc(uid).collection('messages').add(msg);
                }
            }
            //// // console.log('msg.images.length', imageResponse.length);
            this.events.publish('media:showUnsavedImages', msgRef.id, imageResponse);
            for (let i = 0; i < imageResponse.length; i++) {
                //// // console.log('i in loop:', i);
                this.chatImage.url = "";
                this.chatImage.size = imageResponse[i].size;
                this.chatImage.uploadedAt = new Date();
                this.chatImage.userId = uid;
                const mediaDocRef = yield this.mediaRef.doc('images').collection('chats').add(this.chatImage);
                const imgRef = this.fbStorage.ref(`chats/${uid}/messages/${msgRef.id}/images/` + mediaDocRef.id + '.png');
                yield imgRef.putString(imageResponse[i].url, 'data_url');
            }
            this.afs.collection('chats').doc(uid).update({ lastMessage: 'Uploaded an image, click here to see details.',
                lastMessageAt: msg.createdAt, totalMsgs: lastMsgData.totalMsgs + 1 });
            this.events.publish('media:chatImageSuccess');
        });
    }
    broadcastMessage(imageResponse, msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                //// // console.log('bm', msg);
                let broadcastDocId = this.afs.collection('broadcast').ref.doc().id;
                //// // console.log('broadcastDocId', broadcastDocId);
                if (imageResponse.length !== 0) {
                    for (let j = 0; j < imageResponse.length; j++) {
                        const imgRef = this.fbStorage.ref(`broadcast/${broadcastDocId}/images/` + new Date().getTime().toString() + '.png');
                        yield imgRef.putString(imageResponse[j].url, 'data_url');
                        const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                        msg.images.push({ url: downloadURL });
                    }
                    yield this.afs.collection('broadcast').doc(broadcastDocId).set(msg);
                }
                else {
                    yield this.afs.collection('broadcast').doc(broadcastDocId).set(msg);
                }
                this.events.publish('media:broadcastMessageSuccess');
            }
            catch (error) {
                console.dir(error);
                this.events.publish('media:broadcastMessageFailure');
            }
        });
    }
    removeSubscriptions() {
        this.events.unsubscribe('media:addChatImage');
        this.events.unsubscribe('media:broadcastMessage');
    }
}
MediaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MediaService_Factory() { return new MediaService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.AngularFireStorage), i0.ɵɵinject(i4.Storage)); }, token: MediaService, providedIn: "root" });
