import * as tslib_1 from "tslib";
import { first } from 'rxjs/operators';
import { UserService } from './../user/user.service';
import { Storage } from '@ionic/storage';
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/fire/storage";
import * as i4 from "@ionic/storage";
import * as i5 from "../user/user.service";
export class FeedbackService {
    constructor(events, afs, fbStorage, storage, userService) {
        this.events = events;
        this.afs = afs;
        this.fbStorage = fbStorage;
        this.storage = storage;
        this.userService = userService;
    }
    initializeSubscriptions() {
        this.events.subscribe('feedback:saveFeedback', (desc, images) => {
            this.saveFeedback(desc, images);
        });
        this.events.subscribe('feedback:getFeedbacks', () => {
            this.getFeedbacks();
        });
    }
    getFeedbacks() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const feedbacks = yield this.afs.collection('feedbacks', ref => ref
                    .orderBy('createdAt', 'desc')).valueChanges().pipe(first()).toPromise();
                this.events.publish('feedback:publishFeedbacks', feedbacks);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    saveFeedback(desc, images) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const name = yield this.nameInStorage();
                const uid = yield this.uidInStorage();
                const fid = this.afs.collection('feedbacks').ref.doc().id;
                yield this.afs.collection('feedbacks').doc(fid).set({
                    userName: name,
                    userPhone: this.userService.getPhoneNo(),
                    userId: uid,
                    description: desc,
                    images: [],
                    createdAt: new Date()
                });
                if (images.length) {
                    images.forEach((img) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        const imgRef = this.fbStorage.ref(`feedbacks/${fid}/images/` + new Date().getTime().toString() + '.png');
                        yield imgRef.putString(img.url, 'data_url');
                    }));
                }
                this.events.publish('feedback:saveFeedbackSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    nameInStorage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.storage.get('userName').then(name => {
                    resolve(name);
                });
            });
        });
    }
    uidInStorage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                this.storage.get('uid').then(uid => {
                    resolve(uid);
                });
            });
        });
    }
}
FeedbackService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeedbackService_Factory() { return new FeedbackService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AngularFireStorage), i0.ɵɵinject(i4.Storage), i0.ɵɵinject(i5.UserService)); }, token: FeedbackService, providedIn: "root" });
