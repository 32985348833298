import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './services/config/config.service';
export function LanguageLoader(http) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
const appConfig = (config) => {
    return () => {
        return config.loadConfig();
    };
};
const ɵ0 = appConfig;
const ɵ1 = (LanguageLoader);
export class AppModule {
}
export { ɵ0, ɵ1 };
