import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LoadingController, ModalController, Events } from '@ionic/angular';
export class PaymentModalPage {
    constructor(events, loadingController, modalController) {
        this.events = events;
        this.loadingController = loadingController;
        this.modalController = modalController;
    }
    ngOnInit() { }
    ionViewWillEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loader = true;
            this.initializeSubscriptions();
            this.events.publish('chat:getPaymentDetails');
        });
    }
    ionViewWillLeave() {
        this.removeSubscriptions();
    }
    initializeSubscriptions() {
        this.events.subscribe('chat:publishPaymentDetails', (paymentData) => {
            this.paymentData = paymentData;
            this.loader = false;
        });
    }
    closeModal() {
        this.modalController.dismiss();
    }
    removeSubscriptions() {
        this.events.unsubscribe('chat:publishPaymentDetails');
    }
}
