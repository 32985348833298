import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order/order.service';
import { LoadingController, ToastController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ConfigService } from 'src/app/services/config/config.service';
export class PhonepePage {
    constructor(orderService, modalController, loadingController, labelService, toastController, sharedService, configService) {
        this.orderService = orderService;
        this.modalController = modalController;
        this.loadingController = loadingController;
        this.labelService = labelService;
        this.toastController = toastController;
        this.sharedService = sharedService;
        this.configService = configService;
        this.SHARED_LABELS = {};
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () { });
    }
    ionViewWillEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.SHARED_LABELS = this.labelService.labels['SHARED'];
            yield this.getCheckoutUrl();
        });
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: this.SHARED_LABELS['please_wait'],
            });
            yield this.loading.present();
        });
    }
    presentToast(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const toast = yield this.toastController.create({
                message: msg,
                duration: 2000,
            });
            toast.present();
        });
    }
    closeModal() {
        this.modalController.dismiss();
    }
    getCheckoutUrl() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('Attempting to get checkout url!!!');
            const orderRes = yield this.orderService.paymentGatewayProcessor(this.orderData);
            console.log("orderRes: ", orderRes);
            this.orderData['orderDocId'] = orderRes.orderDocId;
            const paymentUrl = yield this.orderService.getPhonepeCheckoutUrl(this.orderData);
            if (paymentUrl) {
                window.open(paymentUrl, "_self");
            }
            else {
                this.orderService.presentFailureAlert();
            }
        });
    }
    timeDelay(time) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, rejects) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                setTimeout(() => {
                    resolve(true);
                }, time * 1000);
            }));
        });
    }
}
