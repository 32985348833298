import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConfigService } from 'src/app/services/config/config.service';
import { CouponCodesService } from 'src/app/services/coupon-codes/coupon-codes.service';
import { LabelService } from 'src/app/services/label/label.service';
export class CouponsListPage {
    constructor(modalController, labelService, couponCodesService, configService) {
        this.modalController = modalController;
        this.labelService = labelService;
        this.couponCodesService = couponCodesService;
        this.configService = configService;
        this.couponName = '';
        this.isLoading = true;
        this.coupons = [];
    }
    ngOnInit() {
        this.COUPONS_LIST_LABELS = this.labelService.labels['COUPONS_LIST'];
        this.currencyCode = this.configService.environment.currencyCode;
    }
    ionViewWillEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.coupons = (yield this.couponCodesService.getCouponCodesForUser()) || [];
            this.isLoading = false;
        });
    }
    closeModal() {
        this.modalController.dismiss();
    }
    applyCoupon(coupon) {
        this.modalController.dismiss({ couponName: (coupon || this.couponName).toUpperCase() });
    }
}
