import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AlertController, Events, LoadingController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
export class RateOrderPage {
    constructor(modalController, events, labelService, loadingController, alertController) {
        this.modalController = modalController;
        this.events = events;
        this.labelService = labelService;
        this.loadingController = loadingController;
        this.alertController = alertController;
        this.reviewPh = '';
        this.ratingData = {
            rating: 5,
            review: '',
            photos: [],
            createdAt: new Date()
        };
    }
    ngOnInit() {
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.RATE_ORDER_LABELS = this.labelService.labels['RATE_ORDER'];
        this.reviewPh = this.RATE_ORDER_LABELS['review_placeholder'];
    }
    ionViewWillEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.initializeSubscriptions();
        });
    }
    ionViewWillLeave() {
        this.removeSubscriptions();
    }
    initializeSubscriptions() {
        this.events.subscribe('rating:submitOrderRatingSuccess', () => {
            if (this.loading) {
                this.loading.dismiss();
            }
            ;
            this.presentAlert(this.RATE_ORDER_LABELS['thanks_for_rating'], true);
        });
    }
    closeModal(status) {
        /* const data = status && status === 'order_rated' ? this.ratingData : null;
         if(data.photos.length) {
           data.photos.forEach(img => {
             img['thumb'] = img.url;
           });
         }
         this.modalController.dismiss(data);*/
        this.modalController.dismiss();
    }
    submit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.presentLoading();
            this.events.publish('rating:submitOrderRating', this.ratingData, this.orderId);
        });
    }
    rate(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.ratingData.rating = data.rating;
            this.ratingData.review = data.review;
            this.ratingData.photos = data.photos;
            yield this.submit();
        });
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: this.SHARED_LABELS['please_wait'],
                duration: 10000,
            });
            yield this.loading.present();
        });
    }
    presentAlert(msg, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: msg,
                backdropDismiss: false,
                buttons: [{
                        text: this.SHARED_LABELS['ok'],
                        handler: () => {
                            if (action) {
                                this.closeModal('order_rated');
                            }
                        }
                    }]
            });
            yield alert.present();
        });
    }
    removeSubscriptions() {
        this.events.unsubscribe('rating:submitOrderRatingSuccess');
    }
}
