import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController, IonSlides } from '@ionic/angular';
import { UtilsService } from '../services/utils/utils.service';
export class ImageModalPage {
    constructor(modalController, utilsService) {
        this.modalController = modalController;
        this.utilsService = utilsService;
        this.showLoader = true;
        this.isFirstSlide = true;
        this.isLastSlide = false;
        this.sliderOpts = {
            initialSlide: 0,
            zoom: {
                maxRatio: 3
            },
            spaceBetween: 10,
            preloadImages: false,
            lazy: true,
            fadeEffect: {
                crossFade: true
            },
        };
        this.spliced = false;
        this.loadingUrl = 'assets/img/img-modal-loading.gif';
        this.imgZoom = false;
    }
    slideChanged() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isFirstSlide = yield this.modalslider.isBeginning();
            this.isLastSlide = yield this.modalslider.isEnd();
        });
    }
    nextSlide() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.modalslider.slideNext();
        });
    }
    prevSlide() {
        this.modalslider.slidePrev();
    }
    ngOnDestroy() {
        if (window.history.state.modal) {
            history.back();
        }
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.utilsService.consoleLog('modal images', this.imgs);
            this.sliderOpts.initialSlide = this.index;
            this.showLoader = false;
            const modalState = {
                modal: true,
                desc: 'State for the modal for correct back button functionality'
            };
            history.pushState(modalState, null);
            // setTimeout(() => {
            //   this.showLoader = false;
            // }, 2000);
        });
    }
    spliceFirstImg() {
        if (this.spliced === false) {
            this.imgs.splice(0, 1);
            this.spliced = true;
        }
    }
    close() {
        this.modalController.dismiss();
    }
    // async zoom() {
    //   this.imgZoom = !this.imgZoom;
    //   const slider = await this.modalslider.getSwiper();
    //   const zoom = slider.zoom;
    //   this.imgZoom ? zoom.in() : zoom.out();
    // }
    zoom(zoomIn) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const slider = yield this.modalslider.getSwiper();
            const zoom = slider.zoom;
            zoomIn ? zoom.in() : zoom.out();
        });
    }
    dismissModal() {
        this.modalController.dismiss();
    }
}
