import * as tslib_1 from "tslib";
import { Renderer2, RendererFactory2 } from '@angular/core';
import { AdminSettingsService } from '../admin-settings/admin-settings.service';
import { ConfigService } from '../config/config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../admin-settings/admin-settings.service";
import * as i3 from "../config/config.service";
export class LoadScriptsService {
    constructor(rendererFactory, document, adminSettingsService, configService) {
        this.document = document;
        this.adminSettingsService = adminSettingsService;
        this.configService = configService;
        this._renderer2 = rendererFactory.createRenderer(null, null);
    }
    loadPaymentLibraries() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const paymentLibraries = [
                /* use cashfree's production library in case the cashfree.development environment variable
                    is set to false; else use cashfree's development library*/
                {
                    'gateway': 'cashfree',
                    'gatewaySrc': this.configService.environment.cashfree && this.configService.environment.cashfree.development ?
                        'https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.sandbox.js' :
                        'https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.prod.js'
                },
            ];
            for (const lib of paymentLibraries) {
                const gatewayType = lib['gateway'];
                const gatewayScriptSrc = lib['gatewaySrc'];
                let paymentGatewayData = yield this.adminSettingsService.getPaymentGateway(gatewayType);
                if (paymentGatewayData && paymentGatewayData.active) {
                    const scriptId = gatewayType.concat('Script');
                    if (!this.document.getElementById(scriptId)) {
                        let scriptElement = this._renderer2.createElement('script');
                        this._renderer2.setAttribute(scriptElement, 'src', gatewayScriptSrc);
                        this._renderer2.setAttribute(scriptElement, 'defer', '');
                        // provide id to the script tag so that it is not appended to the head twice.
                        this._renderer2.setAttribute(scriptElement, 'id', scriptId);
                        this._renderer2.appendChild(this.document.head, scriptElement);
                    }
                }
            }
        });
    }
    loadGoogleMapsLibrary() {
        if (!this.document.getElementById('googleMapsScript')) {
            let scriptElement = this._renderer2.createElement('script');
            this._renderer2.setAttribute(scriptElement, 'src', 'https://maps.google.com/maps/api/js?key=AIzaSyBi8lJj0oSwvEdVcUkM-jiXsGpzKPbp-44');
            // provide id to the script tag so that it is not appended to the head twice.
            this._renderer2.setAttribute(scriptElement, 'id', 'googleMapsScript');
            this._renderer2.appendChild(this.document.head, scriptElement);
        }
    }
}
LoadScriptsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoadScriptsService_Factory() { return new LoadScriptsService(i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i2.AdminSettingsService), i0.ɵɵinject(i3.ConfigService)); }, token: LoadScriptsService, providedIn: "root" });
