import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
export class ThemeService {
    constructor(angularFirestore) {
        this.angularFirestore = angularFirestore;
    }
    initializeThemeSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let data = yield this.angularFirestore.collection('settings').doc('theme').valueChanges().pipe(first()).toPromise();
            const css = `:root {--primary-color: ${data.colors.primary};}`;
            const head = document.getElementsByTagName('head')[0];
            const style = document.createElement('style');
            style.type = 'text/css';
            style.appendChild(document.createTextNode(css));
            head.appendChild(style);
        });
    }
    getThemeSttings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let data = JSON.parse(sessionStorage.getItem("themeSettingsData")) || {};
                if (!(Object.keys(data).length)) {
                    data = yield this.angularFirestore.collection('settings').doc('theme').valueChanges().pipe(first()).toPromise();
                    sessionStorage.setItem("themeSettingsData", JSON.stringify(data));
                }
                resolve(data);
            }));
        });
    }
}
ThemeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThemeService_Factory() { return new ThemeService(i0.ɵɵinject(i1.AngularFirestore)); }, token: ThemeService, providedIn: "root" });
