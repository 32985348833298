import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../loggly-logger/loggly-logger.service";
export class ReferralService {
    constructor(events, afs, logglyService) {
        this.events = events;
        this.afs = afs;
        this.logglyService = logglyService;
        this.referralRef = this.afs.collection('settings').doc('referral');
    }
    initializeSubscriptions() {
        this.events.subscribe('referral:saveSettings', (data) => {
            this.saveSettings(data);
        });
        this.events.subscribe('referral:getReferralSettings', () => {
            this.getReferralSettings();
        });
    }
    getReferralSettings(route) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const settings = yield this.referralRef.valueChanges().pipe(first()).toPromise();
                if (!route) {
                    this.events.publish('referral:publishReferralSettings', settings);
                }
                else {
                    return settings;
                }
            }
            catch (error) {
                console.dir(error);
                this.logglyService.log(error);
            }
        });
    }
    saveSettings(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.referralRef.set(data);
                this.events.publish('referral:saveSettingsSuccess');
                this.events.publish('referral:getReferralSettings');
            }
            catch (error) {
                console.dir(error);
                this.logglyService.log(error);
            }
        });
    }
}
ReferralService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReferralService_Factory() { return new ReferralService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LogglyLoggerService)); }, token: ReferralService, providedIn: "root" });
