import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@ionic/storage";
export class WishlistService {
    constructor(events, afs, storage) {
        this.events = events;
        this.afs = afs;
        this.storage = storage;
        this.userRef = this.afs.collection('users');
    }
    initializeSubscriptions() {
        this.events.subscribe('wishlist:heartClicked', (pid, status, pindex) => {
            this.heartClicked(pid, status, pindex);
        });
        this.events.subscribe('wishlist:getWishlist', () => {
            this.getWishlist();
        });
        this.events.subscribe('wishlist:getAllWishlistProducts', () => {
            this.getAllWishlistProducts();
        });
        this.events.subscribe('wishlist:deleteProduct', (pid, i) => {
            this.deleteProduct(pid, i);
        });
    }
    heartClicked(pid, status, pindex) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const uid = yield this.storage.get('uid');
                if (status === 'not_in_list') {
                    yield this.userRef.doc(uid).collection('wishlist').add({ productId: pid });
                    let wlist = yield this.getStorageWishlist();
                    wlist.push(pid);
                    this.storage.set('wishlistProducts', wlist);
                    this.events.publish('wishlist:getAllWishlistProducts');
                }
                else {
                    const wishlistRef = yield this.userRef.doc(uid).collection('wishlist', ref => ref.where('productId', '==', pid)).snapshotChanges().pipe(map(actions => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return Object.assign({ id }, data);
                    }))).pipe(first()).toPromise();
                    wishlistRef.forEach((w) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        yield this.userRef.doc(uid).collection('wishlist').doc(w.id).delete();
                    }));
                    let wlist = yield this.getStorageWishlist();
                    const index = wlist.indexOf(pid);
                    wlist.splice(index, 1);
                    this.storage.set('wishlistProducts', wlist);
                    this.events.publish('wishlist:getAllWishlistProducts');
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getWishlist() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const wishlist = [];
            const uid = yield this.getStorageUid();
            const wishlistData = yield this.userRef.doc(uid).collection('wishlist').valueChanges().pipe(first()).toPromise();
            wishlistData.forEach(w => {
                wishlist.push(w.productId);
            });
            //// // console.log('wishlist', wishlist);
            this.storage.set('wishlistProducts', wishlist);
        });
    }
    getAllWishlistProducts() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const wishlistPids = [];
            const products = [];
            const uid = yield this.getStorageUid();
            const wishlistData = yield this.userRef.doc(uid).collection('wishlist').valueChanges().pipe(first()).toPromise();
            wishlistData.forEach(w => {
                wishlistPids.push(w.productId);
            });
            for (const pid of wishlistPids) {
                const product = yield this.afs.collection('products').doc(pid).valueChanges().pipe(first()).toPromise();
                if (product) {
                    products.push({ id: pid, data: product });
                }
            }
            this.events.publish('wishlist:allWishlistProducts', products);
        });
    }
    deleteProduct(pid, i) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const uid = yield this.getStorageUid();
                const wishlistRef = yield this.userRef.doc(uid).collection('wishlist', ref => ref.where('productId', '==', pid)).snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                wishlistRef.forEach((w) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    yield this.userRef.doc(uid).collection('wishlist').doc(w.id).delete();
                }));
                yield this.getWishlist();
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getStorageUid() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.storage.get('uid').then((val) => {
                    resolve(val);
                });
            }));
        });
    }
    getStorageWishlist() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.storage.get('wishlistProducts').then((val) => {
                    resolve(val);
                });
            }));
        });
    }
}
WishlistService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WishlistService_Factory() { return new WishlistService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.Storage)); }, token: WishlistService, providedIn: "root" });
