import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { AppointmentService } from 'src/app/services/appointment/appointment.service';
import { LabelService } from 'src/app/services/label/label.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UserService } from 'src/app/services/user/user.service';
export class AppointmentSchedulesPage {
    constructor(userService, appointmentService, sharedService, labelService, modalController, router) {
        this.userService = userService;
        this.appointmentService = appointmentService;
        this.sharedService = sharedService;
        this.labelService = labelService;
        this.modalController = modalController;
        this.router = router;
        this.schedules = [];
        this.maxDays = 0;
        this.scheduleSlots = [];
        this.selectedTime = {};
        this.item = {};
        this.booked = false;
    }
    ngOnInit() {
        // console.log('schedules', this.schedules);
        // console.log('maxDays', this.maxDays);
        // console.log('item', this.item);
        this.getSchedules();
    }
    getSchedules() {
        for (let index = 0; index < this.maxDays; index++) {
            let dayToCheck = moment().add(index, 'days');
            const schedule = this.schedules.filter(s => s.day === dayToCheck.format('dddd'));
            if (schedule.length) {
                if (schedule[0].active && schedule[0].schedule.length) {
                    this.scheduleSlots.push({
                        date: dayToCheck.toDate(),
                        schedules: schedule[0].schedule,
                        day: schedule[0].day,
                        active: false
                    });
                }
            }
        }
        this.scheduleSlots[0].active = true;
    }
    selectDate(i) {
        this.scheduleSlots[i].active = true;
        this.scheduleSlots.forEach((slot, index) => {
            if (index !== i) {
                slot.active = false;
            }
        });
    }
    getActiveTimeSchedules() {
        return this.scheduleSlots.filter(s => s.active === true)[0].schedules;
    }
    setTimeSlot(event) {
        this.selectedTime = event.target.value;
    }
    bookAppointment() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.sharedService.presentLoading();
            const scheduleSlot = this.scheduleSlots.filter(s => s.active === true)[0];
            const data = {
                item: this.item,
                schedule: {
                    date: moment(scheduleSlot.date).format('DD-MM-YYYY'),
                    slot: {
                        start: this.selectedTime.start,
                        end: this.selectedTime.end
                    },
                    day: scheduleSlot.day
                },
                vendor: this.vendor,
                user: {
                    id: this.userService.getUserId(),
                    name: this.userService.getUserName()
                },
                status: 'accepted'
            };
            const res = yield this.appointmentService.bookAppointment(data, this.selectedTime.slotLimit);
            this.sharedService.loading.dismiss();
            if (res === 'added') {
                this.sharedService.presentAlert(this.labelService.labels['APPOINTMENT_SCHEDULE']['appointment_booked']);
                this.booked = true;
                this.closeModal();
                //this.router.navigate(['/']);
            }
            else if (res === 'not_available') {
                this.sharedService.presentAlert(this.labelService.labels['APPOINTMENT_SCHEDULE']['slot_not_available']);
            }
            else {
                this.sharedService.presentAlert(this.labelService.labels['SHARED']['some_issue_msg']);
            }
        });
    }
    closeModal() {
        this.modalController.dismiss({ booked: this.booked });
    }
    getDate(date) {
        return moment(date).format('DD MMM');
    }
    isBookNowDisabled() {
        return !Object.keys(this.selectedTime).length;
    }
}
