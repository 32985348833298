import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, first } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/fire/storage";
export class ProductOptionsService {
    constructor(events, afs, fbStorage) {
        this.events = events;
        this.afs = afs;
        this.fbStorage = fbStorage;
    }
    initializeSubscriptions() {
        this.events.subscribe('product-options:getAllProductOptions', (pid) => {
            this.getAllProductOptions(pid);
        });
        this.events.subscribe('product-options:deleteProductOption', (oid, pid) => {
            this.deleteProductOption(oid, pid);
        });
        this.events.subscribe('product-options:editProductOption', (editdata, pid, oid, listOfImages, barcode) => {
            this.editProductOption(editdata, pid, oid, listOfImages, barcode);
        });
        this.events.subscribe('product-options:getOptionData', (pid, oid) => {
            this.getOptionData(pid, oid);
        });
    }
    getAllProductOptions(pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const optionsRef = this.afs.collection('products').doc(pid).collection('options');
                const optionsSnap = optionsRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                })));
                const options = yield optionsSnap.pipe(first()).toPromise();
                this.events.publish('product-options:publishAllProductOptions', options);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    deleteProductOption(oid, pid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('products').doc(pid).collection('options').doc(oid).delete();
                this.events.publish('product-options:getAllProductOptions', pid);
                this.events.publish('product-options:deleteProductOptionSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    editProductOption(editdata, pid, oid, listOfImages, barcode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //// // console.log('editProduct data...', editdata, listOfImages);
            let colorObj = {};
            if (editdata.color.hasOwnProperty('image') && editdata.color.image.includes('data:image/jpeg;base64,')) {
                colorObj = Object.assign({}, editdata.color);
                delete editdata.color.image;
            }
            if (barcode !== '') {
                const imgRef = this.fbStorage.ref(`productOptions/${pid}/options/${oid}/barcode/` + new Date().getTime().toString() + '.png');
                yield imgRef.putString(barcode, 'data_url');
                const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                editdata["barcode"] = downloadURL;
                yield this.afs.collection('products').doc(pid).collection('options').doc(oid).update(editdata);
            }
            else {
                yield this.afs.collection('products').doc(pid).collection('options').doc(oid).update(editdata);
            }
            if (colorObj.hasOwnProperty('image')) {
                //// // console.log('colorObj', colorObj);
                const imgRef = this.fbStorage.ref(`productOptions/${pid}/options/${oid}/color/` + new Date().getTime().toString() + '.png');
                yield imgRef.putString(colorObj.image, 'data_url');
                const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                yield this.afs.collection('products').doc(pid).collection('options').doc(oid).update({
                    color: {
                        image: downloadURL,
                        name: colorObj.name,
                        code: colorObj.code
                    }
                });
            }
            if (listOfImages.length !== 0) {
                try {
                    yield this.addimgtoFirebase(pid, oid, listOfImages);
                    this.events.publish('product-options:editSuccess', 'Success', 'Product edited successfully!');
                }
                catch (error) {
                    //// // console.log(error);
                    this.events.publish('product-options:editFailure', 'Failure', 'Product not edited successfully!');
                }
            }
            else if (!editdata.images.length && !listOfImages.length) {
                yield this.afs.collection('products').doc(pid).collection('options').doc(oid).update({
                    coverPic: {
                        url: 'assets/img/placeholder-img.jpg',
                        mob: 'assets/img/placeholder-img.jpg',
                        thumb: 'assets/img/placeholder-img.jpg'
                    }
                });
                this.events.publish('product-options:editSuccess', 'Success', 'Product edited successfully!');
            }
            else {
                //// // console.log('in else part');
                this.events.publish('product-options:editSuccess', 'Success', 'Product edited successfully!');
            }
        });
    }
    addimgtoFirebase(pid, oid, imgdataAndSize) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const image = {
                url: null,
                size: null,
                uploadedAt: null,
                productId: null,
                optionId: null
            };
            for (let i = 0; i < imgdataAndSize.length; i++) {
                image.url = '';
                image.size = imgdataAndSize[i].size;
                image.uploadedAt = new Date();
                image.productId = pid;
                image.optionId = oid;
                const mediaDocRef = yield this.afs.collection('media').doc('images').collection('productOptions').add(image);
                const imgRef = this.fbStorage.ref(`productOptions/${pid}/options/${oid}/images/` + mediaDocRef.id + '.png');
                yield imgRef.putString(imgdataAndSize[i].base64Img, 'data_url');
                if (imgdataAndSize[i].cover === true) {
                    const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                    this.afs.collection('products').doc(pid).collection('options').doc(oid).update({ coverPic: { imageId: mediaDocRef.id, url: downloadURL } });
                }
            }
        });
    }
    getOptionData(pid, oid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const optData = yield this.afs.collection('products').doc(pid).collection('options').doc(oid).valueChanges().pipe(first()).toPromise();
                const productData = yield this.afs.collection('products').doc(pid).valueChanges().pipe(first()).toPromise();
                this.events.publish('product-options:publishOptionData', optData, productData);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
}
ProductOptionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductOptionsService_Factory() { return new ProductOptionsService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AngularFireStorage)); }, token: ProductOptionsService, providedIn: "root" });
