import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/angular";
import * as i3 from "../loggly-logger/loggly-logger.service";
export class MembershipService {
    constructor(angularFirestore, events, logglyService) {
        this.angularFirestore = angularFirestore;
        this.events = events;
        this.logglyService = logglyService;
        this.membershipRef = this.angularFirestore.collection('features').doc('membership');
    }
    initializeSubscriptions() {
        this.events.subscribe('membership:getMembershipSettings', () => {
            this.getMembershipSettings();
        });
    }
    getMembershipSettings() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const settings = yield this.membershipRef.valueChanges().pipe(first()).toPromise();
                this.events.publish('membership:membershipSettings', settings);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'membership-service:getMembershipSettings';
                this.logglyService.log(error);
            }
        });
    }
}
MembershipService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MembershipService_Factory() { return new MembershipService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.LogglyLoggerService)); }, token: MembershipService, providedIn: "root" });
