import * as tslib_1 from "tslib";
import { first } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
export class ConfigService {
    constructor(angularFirestore) {
        this.angularFirestore = angularFirestore;
        this.environment = environment;
    }
    loadConfig() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.angularFirestore.collection('settings').doc('environment').valueChanges().pipe(first()).toPromise().then(config => {
                let configKeys = Object.keys(config);
                let envKeys = Object.keys(this.environment);
                envKeys.forEach(key => {
                    if (!configKeys.includes(key)) {
                        config[key] = this.environment[key];
                    }
                });
                this.environment = config;
                // // console.log('this.environment ', this.environment);
            });
        });
    }
    getConfig() {
        return this.environment;
    }
}
ConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(i0.ɵɵinject(i1.AngularFirestore)); }, token: ConfigService, providedIn: "root" });
