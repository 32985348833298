import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoadScriptsService } from 'src/app/services/load-scripts/load-scripts.service';
import { OrderService } from 'src/app/services/order/order.service';
import { WalletService } from 'src/app/services/wallet/wallet.service';
export class CashfreePage {
    constructor(orderService, modalController, loadScriptsService, walletService) {
        this.orderService = orderService;
        this.modalController = modalController;
        this.loadScriptsService = loadScriptsService;
        this.walletService = walletService;
        this.showLoader = true;
        this.loadScriptsService.loadPaymentLibraries();
    }
    ngOnInit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const cashfree = new Cashfree();
            const paymentDom = document.getElementById("payment-form");
            let success, failure, token;
            if (this.walletTopup) {
                token = yield this.walletService.createOrderCashfree(this.orderData);
                success = (data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (data.order && data.order.status == "PAID") {
                        this.closeModal();
                        yield this.walletService.getOrderDetailsCashfree(data.order.orderId, this.orderData);
                    }
                    else {
                        // console.log('inside else of not paid');
                        this.closeModal();
                        this.walletService.presentFailureAlert();
                    }
                });
                failure = (data) => {
                    // console.log('inisde failure');
                    this.closeModal();
                    this.walletService.presentFailureAlert();
                };
            }
            else {
                const { cashfreeToken, orderCashfree } = yield this.orderService.createOrderCashfree(this.orderData, this.autoConfirm);
                token = cashfreeToken;
                // console.log('token:', cashfreeToken);
                this.orderData = orderCashfree;
                success = (data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (data.order && data.order.status == "PAID") {
                        this.closeModal();
                        //this.cashfreeService.paymentSuccess({...this.orderData, orderId: data.order.orderId});
                        yield this.orderService.getOrderDetailsCashfree(data.order.orderId, this.orderData, this.autoConfirm);
                    }
                    else {
                        this.closeModal();
                        this.orderService.cashfreePaymentFailed(this.autoConfirm, this.orderData);
                        this.orderService.presentFailureAlert();
                    }
                });
                failure = (data) => {
                    this.closeModal();
                    this.orderService.cashfreePaymentFailed(this.autoConfirm, this.orderData);
                    this.orderService.presentFailureAlert();
                };
            }
            const dropConfig = {
                "components": [
                    "order-details",
                    "card",
                    "netbanking",
                    "app",
                    "upi-collect",
                    "upi-intent"
                ],
                "orderToken": token,
                "onSuccess": success,
                "onFailure": failure,
                "style": {
                    "backgroundColor": "#ffffff",
                    "color": "#11385b",
                    "fontFamily": "Lato",
                    "fontSize": "14px",
                    "errorColor": "#ff0000",
                    "theme": "light",
                }
            };
            this.showLoader = false;
            cashfree.initialiseDropin(paymentDom, dropConfig);
        });
    }
    closeModal() {
        this.modalController.dismiss();
    }
}
