import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import * as firebase from 'firebase';
import { first, map } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../loggly-logger/loggly-logger.service";
export class MultiRegionService {
    constructor(events, afs, logglyService) {
        this.events = events;
        this.afs = afs;
        this.logglyService = logglyService;
        this.multiRegionRef = this.afs.collection('features').doc('multiRegion').collection('regions');
    }
    initializeSubscriptions() {
        this.events.subscribe('multi-region:saveRegion', (regionData) => {
            this.saveRegion(regionData);
        });
        this.events.subscribe('multi-region:toggleMultiRegionActive', (status) => {
            this.toggleMultiRegionActive(status);
        });
        this.events.subscribe('multi-region:getActiveStatus', () => {
            this.getActiveStatus();
        });
        this.events.subscribe('multi-region:getAllRegions', () => {
            this.getAllRegions();
        });
        this.events.subscribe('multi-region:toggleRegionActive', (status, id) => {
            this.toggleRegionActive(status, id);
        });
        this.events.subscribe('multi-region:deleteRegion', (id) => {
            this.deleteRegion(id);
        });
        this.events.subscribe('multi-region:getAllActiveRegions', () => {
            this.getAllActiveRegions();
        });
    }
    saveRegion(regionData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let regionId = '';
                let regionClone = JSON.parse(JSON.stringify(regionData));
                if (regionClone.hasOwnProperty('id')) {
                    regionId = regionClone.id;
                    delete regionData.id;
                }
                else {
                    regionId = this.multiRegionRef.ref.doc().id;
                }
                regionData['createdAt'] = new Date();
                yield this.multiRegionRef.doc(regionId).set(regionData);
                this.events.publish('multi-region:regionSaved');
                this.events.publish('multi-region:getAllRegions');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'multi-region-service:saveRegion';
                this.logglyService.log(error);
            }
        });
    }
    toggleMultiRegionActive(status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('features').doc('multiRegion').set({ active: status });
                this.events.publish('multi-region:multiRegionActiveChanged');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'multi-region-service:toogleMultiRegionActive';
                this.logglyService.log(error);
            }
        });
    }
    getActiveStatus(route) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const multiRegionDoc = yield this.afs.collection('features').doc('multiRegion').valueChanges().pipe(first()).toPromise();
                if (route === 'service') {
                    return multiRegionDoc;
                }
                else {
                    this.events.publish('multi-region:publishActiveStatus', multiRegionDoc);
                }
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'multi-region-service:getActiveStatus';
                this.logglyService.log(error);
            }
        });
    }
    getAllRegions() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const multiRegions = yield this.afs.collection('features').doc('multiRegion').collection('regions', ref => ref
                    .orderBy('createdAt', 'desc')).snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                // console.log('multiRegions', multiRegions);
                this.events.publish('multi-region:publishAllRegions', multiRegions);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'multi-region-service:getAllRegions';
                this.logglyService.log(error);
            }
        });
    }
    toggleRegionActive(status, id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.multiRegionRef.doc(id).update({ active: status });
                this.events.publish('multi-region:regionActiveChanged');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'multi-region-service:toggleRegionActive';
                this.logglyService.log(error);
            }
        });
    }
    deleteRegion(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.multiRegionRef.doc(id).delete();
                this.events.publish('multi-region:regionDeleted');
                this.events.publish('multi-region:getAllRegions');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'multi-region-service:deleteRegion';
                this.logglyService.log(error);
            }
        });
    }
    getAllActiveRegions(callType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const multiRegions = yield this.afs.collection('features').doc('multiRegion').collection('regions', ref => ref
                    .where('active', '==', true)).snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                // console.log('multiRegions', multiRegions);
                if (callType && callType === 'return') {
                    return multiRegions;
                }
                else {
                    this.events.publish('multi-region:publishAllActiveRegions', multiRegions);
                }
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'multi-region-service:getAllRegions';
                this.logglyService.log(error);
            }
        });
    }
    getNearestStoreRegion(address) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const regions = yield this.getAllActiveRegions('return');
                    const storeRegion = firebase.functions().httpsCallable('orders-getNearestStoreRegion');
                    storeRegion({ address, regions }).then((res) => {
                        resolve(res.data);
                    });
                }
                catch (error) {
                    // console.log(error);
                    error['location'] = 'multi-region-service:getNearestStoreRegion';
                    this.logglyService.log(error);
                    resolve({ status: 'failure' });
                }
            }));
        });
    }
}
MultiRegionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MultiRegionService_Factory() { return new MultiRegionService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LogglyLoggerService)); }, token: MultiRegionService, providedIn: "root" });
