import * as tslib_1 from "tslib";
import { Storage } from '@ionic/storage';
import { Router, ActivatedRoute } from '@angular/router';
import { Events, LoadingController, ModalController, NavController } from '@ionic/angular';
import { NgZone, OnInit } from '@angular/core';
import { LabelService } from 'src/app/services/label/label.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/services/config/config.service';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
export class SelectLanguagePage {
    constructor(events, modalController, router, labelService, translateService, storage, ngZone, navController, route, loadingController, configService, splashScreen) {
        this.events = events;
        this.modalController = modalController;
        this.router = router;
        this.labelService = labelService;
        this.translateService = translateService;
        this.storage = storage;
        this.ngZone = ngZone;
        this.navController = navController;
        this.route = route;
        this.loadingController = loadingController;
        this.configService = configService;
        this.splashScreen = splashScreen;
        this.headerText = '';
        this.showLoader = true;
        this.languages = [];
        this.routeFromSidemenu = false;
    }
    ngOnInit() {
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.SELECT_LANGUAGE_LABELS = this.labelService.labels['SELECT_LANGUAGE'];
        this.headerText = this.SELECT_LANGUAGE_LABELS['header_text'];
    }
    ionViewDidEnter() {
        this.splashScreen.hide();
    }
    ionViewWillEnter() {
        this.initializeSubscriptions();
        this.events.publish('language:getAddedLanguages');
    }
    ionViewWillLeave() {
        this.removeSubscriptions();
    }
    initializeSubscriptions() {
        this.events.subscribe('language:publishAddedLanguages', (langs) => {
            this.storage.get('appLang').then((langCode) => {
                if (langCode && langCode !== undefined) {
                    langs.map(lang => {
                        if (lang.code === langCode) {
                            lang.isDefault = true;
                        }
                        else {
                            lang.isDefault = false;
                        }
                    });
                }
                this.languages = langs;
                this.showLoader = false;
            });
        });
    }
    selectLang(i) {
        for (let index = 0; index < this.languages.length; index++) {
            if (i === index) {
                this.languages[index].isDefault = true;
            }
            else {
                this.languages[index].isDefault = false;
            }
        }
    }
    onClickSelect() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.languages.length) {
                yield this.presentLoading();
                this.languages.forEach(lang => {
                    if (lang.isDefault) {
                        this.translateService.setDefaultLang(lang.code);
                        this.translateService.use(lang.code);
                        this.labelService.intializeSubscriptions();
                        this.storage.set('appLang', lang.code);
                        this.loading.dismiss();
                        this.ngZone.run(() => {
                            this.navController.navigateRoot(['']);
                        });
                        this.modalDismiss();
                    }
                });
            }
            else {
                this.storage.set('appLang', this.configService.environment.DEFAULT_APP_LANG);
                this.ngZone.run(() => {
                    this.navController.navigateRoot(['']);
                });
                this.modalDismiss();
            }
        });
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                duration: 2000,
            });
            yield this.loading.present();
        });
    }
    removeSubscriptions() {
        this.events.unsubscribe('language:publishAddedLanguages');
    }
    modalDismiss() {
        this.modalController.dismiss();
    }
}
