import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AlertController, Events, LoadingController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
export class RateProductPage {
    constructor(modalController, events, labelService, loadingController, alertController) {
        this.modalController = modalController;
        this.events = events;
        this.labelService = labelService;
        this.loadingController = loadingController;
        this.alertController = alertController;
        this.reviewPh = '';
        this.ratingData = {
            status: 'pending',
            rating: 5,
            review: '',
            photos: [],
            userName: ''
        };
        this.rating = 5;
        this.productIndex = -1;
        this.orderId = '';
    }
    ngOnInit() {
        // // console.log(this.productId);
        // // console.log(this.product);
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.RATE_PRODUCT_LABELS = this.labelService.labels['RATE_PRODUCT'];
        this.reviewPh = this.RATE_PRODUCT_LABELS['review_placeholder'];
    }
    ionViewWillEnter() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.initializeSubscriptions();
            yield this.presentLoading();
            this.events.publish('rating:checkProductBought', this.productId, this.orderId ? 'order-product' : '');
        });
    }
    ionViewWillLeave() {
        this.removeSubscriptions();
    }
    initializeSubscriptions() {
        this.events.subscribe('rating:submitRatingSuccess', () => {
            if (this.loading) {
                this.loading.dismiss();
            }
            ;
            this.presentAlert(this.RATE_PRODUCT_LABELS['thanks_for_rating'], true);
        });
        this.events.subscribe('rating:productChecked', (isProductBought) => {
            if (this.loading) {
                this.loading.dismiss();
            }
            ;
            if (!isProductBought) {
                this.presentAlert(this.RATE_PRODUCT_LABELS['product_cant_review'], true);
            }
        });
        this.events.subscribe('rating:productReviewed', () => {
            if (this.loading) {
                this.loading.dismiss();
            }
            ;
            this.presentAlert(this.RATE_PRODUCT_LABELS['product_already_reviewed'], true);
        });
    }
    removeSubscriptions() {
        this.events.unsubscribe('rating:submitRatingSuccess');
        this.events.unsubscribe('rating:productChecked');
        this.events.unsubscribe('rating:productReviewed');
    }
    closeModal(action) {
        this.modalController.dismiss(action);
    }
    submit() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.ratingData.userName) {
                this.presentAlert(this.RATE_PRODUCT_LABELS['enter_public_name'], false);
            }
            else {
                yield this.presentLoading();
                this.events.publish('rating:submitRating', this.ratingData, this.parentProductId, this.productIndex, this.orderId);
                this.events.publish('rating:submitRating', this.ratingData, this.productId);
            }
        });
    }
    rate(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.ratingData.rating = data.rating;
            this.ratingData.review = data.review;
            this.ratingData.photos = data.photos;
            this.ratingData.userName = data.userName;
            yield this.submit();
        });
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: this.SHARED_LABELS['please_wait'],
                duration: 10000,
            });
            yield this.loading.present();
        });
    }
    presentAlert(msg, action) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: msg,
                backdropDismiss: false,
                buttons: [{
                        text: this.SHARED_LABELS['ok'],
                        handler: () => {
                            if (action) {
                                this.closeModal(this.orderId ? 'order-product' : '');
                            }
                        }
                    }]
            });
            yield alert.present();
        });
    }
}
