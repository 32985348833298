import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { first, map } from 'rxjs/operators';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../loggly-logger/loggly-logger.service";
export class LanguageService {
    constructor(events, afs, logglyService) {
        this.events = events;
        this.afs = afs;
        this.logglyService = logglyService;
    }
    initializeSubscriptions() {
        this.events.subscribe('language:getAvaialbleLanguages', () => {
            this.getAvaialbleLanguages();
        });
        this.events.subscribe('language:addLanguages', (langs) => {
            this.addLanguages(langs);
        });
        this.events.subscribe('language:getAddedLanguages', () => {
            this.getAddedLanguages();
        });
        this.events.subscribe('language:makeDefault', (id, languages) => {
            this.makeDefault(id, languages);
        });
        this.events.subscribe('language:deleteLang', (id, languages) => {
            this.deleteLang(id, languages);
        });
    }
    getAvaialbleLanguages() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const langs = yield this.afs.collection('languages').snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                // console.log('langs', langs);
                this.events.publish('language:publishAvaialbleLanguages', langs);
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'language-service:getAvaialbleLanguages';
                this.logglyService.log(error);
            }
        });
    }
    addLanguages(langs) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                for (const l of langs) {
                    yield this.afs.collection('languages').doc(l.id).update({
                        isAdded: l.isAdded
                    });
                }
                this.events.publish('language:addLanguagesSuccess');
                this.events.publish('language:getAddedLanguages');
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'language-service:addLanguages';
                this.logglyService.log(error);
            }
        });
    }
    getAddedLanguages(route) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const langs = yield this.afs.collection('languages', ref => ref
                    .where('isAdded', '==', true)
                    .orderBy('sortedAt', 'asc')).snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                // console.log('langs', langs);
                if (!route) {
                    this.events.publish('language:publishAddedLanguages', langs);
                }
                else {
                    return langs;
                }
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'language-service:getAddedLanguages';
                this.logglyService.log(error);
            }
        });
    }
    updateLangPosition(id, changedDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                // console.log('id & cdate', id, changedDate);
                yield this.afs.doc(`languages/${id}`).update({ sortedAt: changedDate });
                this.events.publish('language:updateLangPostionSucess');
            }
            catch (error) {
                error['location'] = 'language-service:updateLangPosition';
                this.logglyService.log(error);
            }
        });
    }
    makeDefault(id, languages) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                for (const l of languages) {
                    if (l.id === id) {
                        yield this.afs.doc(`languages/${l.id}`).update({ isDefault: true });
                    }
                    else {
                        yield this.afs.doc(`languages/${l.id}`).update({ isDefault: false });
                    }
                }
                this.events.publish('language:makeDefaultSuccess', id);
                this.events.publish('language:getAddedLanguages');
            }
            catch (error) {
                error['location'] = 'language-service:makeDefault';
                this.logglyService.log(error);
            }
        });
    }
    deleteLang(id, languages) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.doc(`languages/${id}`).update({ isAdded: false });
                this.events.publish('language:deleteLangSuccess', id);
                this.events.publish('language:getAddedLanguages');
            }
            catch (error) {
                error['location'] = 'language-service:deleteLang';
                this.logglyService.log(error);
            }
        });
    }
}
LanguageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LanguageService_Factory() { return new LanguageService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.LogglyLoggerService)); }, token: LanguageService, providedIn: "root" });
