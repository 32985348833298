import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
export class HelpService {
    constructor(events, afs) {
        this.events = events;
        this.afs = afs;
    }
    initializeSubscriptions() {
        this.events.subscribe('help:getHelpData', () => {
            this.getHelpData();
        });
    }
    getHelpData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const helpData = yield this.afs.collection('help', ref => ref
                    .orderBy('order', 'asc')).valueChanges().pipe(first()).toPromise();
                this.events.publish('help:publishHelpData', helpData);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
}
HelpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelpService_Factory() { return new HelpService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore)); }, token: HelpService, providedIn: "root" });
