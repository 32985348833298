import * as tslib_1 from "tslib";
import { first, map } from 'rxjs/operators';
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Storage } from '@ionic/storage';
import { SharedService } from '../shared/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/fire/storage";
import * as i4 from "@ionic/storage";
import * as i5 from "../shared/shared.service";
export class BrandsService {
    constructor(events, afs, fbStorage, storage, sharedService) {
        this.events = events;
        this.afs = afs;
        this.fbStorage = fbStorage;
        this.storage = storage;
        this.sharedService = sharedService;
    }
    initializeSubscriptions() {
        this.events.subscribe('brands:getAllBrandsForAdmin', () => {
            this.getAllBrandsForAdmin();
        });
        this.events.subscribe('brands:getAllBrandsForUser', () => {
            this.getAllBrandsForUser();
        });
        this.events.subscribe('brands:saveBrand', (data, img, banner, bid) => {
            this.saveBrand(data, img, banner, bid);
        });
        this.events.subscribe('brands:deleteBrand', (bid) => {
            this.deleteBrand(bid);
        });
        this.events.subscribe('brands:getBrandsData', (bid) => {
            this.getBrandsData(bid);
        });
        this.setBrandsInLocalStorage();
        this.events.subscribe('brands:removeBrandsSubs', () => {
            if (this.brandsSubs) {
                this.brandsSubs.unsubscribe();
            }
        });
    }
    getAllBrandsForAdmin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const brandsData = yield this.getAllBrands();
            if (!brandsData.length) {
                this.events.publish('brands:noBrandAvailableForAdmin');
            }
            else {
                this.events.publish('brands:publishAllBrandsForAdmin', brandsData);
            }
        });
    }
    getAllBrandsForUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const region = yield this.sharedService.checkRegionIdForApi();
                let regionId = region.regionId;
                let brandsRef;
                if (regionId) {
                    brandsRef = this.afs.collection('brands', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true)
                        .where('regionId', 'array-contains', regionId));
                }
                else {
                    brandsRef = this.afs.collection('brands', ref => ref
                        .where('status', '==', true)
                        .orderBy('sortedAt', 'desc'));
                }
                const brandsSnap = brandsRef.snapshotChanges().pipe(map((actions) => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                })));
                const brandsSub = brandsSnap.subscribe((brandsData) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (!brandsData.length) {
                        this.events.publish('brands:noBrandAvailableForUser');
                        brandsSub.unsubscribe();
                    }
                    else {
                        this.events.publish('brands:publishAllBrandsForUser', brandsData);
                        brandsSub.unsubscribe();
                    }
                }));
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getAllBrands() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.storage.get('userRole').then((role) => {
                    let brandsRef;
                    if (role === 'admin') {
                        brandsRef = this.afs.collection('brands', ref => ref.orderBy('sortedAt', 'desc'));
                    }
                    else {
                        brandsRef = this.afs.collection('brands', ref => ref
                            .where('status', '==', true)
                            .orderBy('sortedAt', 'desc'));
                    }
                    const brandsSnap = brandsRef.snapshotChanges().pipe(map((actions) => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return Object.assign({ id }, data);
                    })));
                    this.brandsSubs = brandsSnap.subscribe((brandsData) => {
                        resolve(brandsData);
                    });
                });
            }));
        });
    }
    saveBrand(data, img, banner, bid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let brandid = bid;
                let baseImg = {};
                let storageImg = {};
                let bannerBaseImg = {};
                let bannerStorageImg = {};
                if (!brandid) {
                    brandid = this.afs.collection('brands').ref.doc().id;
                }
                if (img.length) {
                    img.forEach(i => {
                        if (i.url.includes('data:image/jpeg;base64,')) {
                            baseImg = i;
                        }
                        else {
                            storageImg = i;
                        }
                    });
                }
                if (banner.length) {
                    banner.forEach(i => {
                        if (i.url.includes('data:image/jpeg;base64,')) {
                            bannerBaseImg = i;
                        }
                        else {
                            bannerStorageImg = i;
                        }
                    });
                }
                yield this.afs.collection('brands').doc(brandid).set({
                    status: data.status,
                    name: data.name,
                    image: storageImg,
                    banner: bannerStorageImg,
                    sortedAt: data.sortedAt
                });
                if (baseImg.hasOwnProperty('url')) {
                    const imgRef = this.fbStorage.ref(`brands/${brandid}/image/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(baseImg.url, 'data_url');
                }
                if (bannerBaseImg.hasOwnProperty('url')) {
                    const imgRef = this.fbStorage.ref(`brandsBanner/${brandid}/image/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(bannerBaseImg.url, 'data_url');
                }
                this.events.publish('brands:saveBrandSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    deleteBrand(bid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('brands').doc(bid).delete();
                this.events.publish('brands:deleteBrandSuccess');
            }
            catch (error) {
            }
        });
    }
    updateBrandsPosition(id, changedDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.afs.doc(`brands/${id}`).update({ sortedAt: changedDate });
            this.events.publish('brands:updateBrandsPostionSucess');
        });
    }
    getBrandsData(bid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const brandData = yield this.afs.collection('brands').doc(bid).valueChanges().pipe(first()).toPromise();
                this.events.publish('brands:publishBrandsData', brandData);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getAllBrandsForSideMenu() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const storageBrands = JSON.parse(localStorage.getItem('brands')) || [];
                if (!storageBrands.length) {
                    const brandsData = yield this.setBrandsInLocalStorage();
                    return brandsData;
                }
                else {
                    return storageBrands;
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    setBrandsInLocalStorage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const region = yield this.sharedService.checkRegionIdForApi();
                let regionId = region.regionId;
                let brandsRef;
                if (regionId) {
                    brandsRef = this.afs.collection('brands', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true)
                        .where('regionId', 'array-contains', regionId));
                }
                else {
                    brandsRef = this.afs.collection('brands', ref => ref
                        .orderBy('sortedAt', 'desc')
                        .where('status', '==', true));
                }
                const brandsSnap = brandsRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                })));
                const brandsData = yield brandsSnap.pipe(first()).toPromise();
                localStorage.setItem('brands', JSON.stringify(brandsData));
                resolve(brandsData);
            }));
        });
    }
}
BrandsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrandsService_Factory() { return new BrandsService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AngularFireStorage), i0.ɵɵinject(i4.Storage), i0.ɵɵinject(i5.SharedService)); }, token: BrandsService, providedIn: "root" });
