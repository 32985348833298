import * as tslib_1 from "tslib";
import { UserService } from 'src/app/services/user/user.service';
import { Storage } from '@ionic/storage';
import { first, map } from 'rxjs/operators';
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { SharedService } from '../shared/shared.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/fire/storage";
import * as i4 from "@ionic/storage";
import * as i5 from "../user/user.service";
import * as i6 from "../shared/shared.service";
export class ServicesFeatureService {
    constructor(events, afs, fbStorage, storage, userService, sharedService) {
        this.events = events;
        this.afs = afs;
        this.fbStorage = fbStorage;
        this.storage = storage;
        this.userService = userService;
        this.sharedService = sharedService;
        this.serviceRef = this.afs.collection('services');
    }
    initializeSubscriptions() {
        this.events.subscribe('services-feature:getAllServices', () => {
            this.getAllServices();
        });
        this.events.subscribe('services-feature:getAllServicesForHome', () => {
            this.getAllServicesForHome();
        });
        this.events.subscribe('services-feature:getServicesActiveStatus', () => {
            this.getServicesActiveStatus();
        });
        this.events.subscribe('services-feature:changeServiceStatus', (status) => {
            this.changeServiceStatus(status);
        });
        this.events.subscribe('services-feature:deleteService', (sid) => {
            this.deleteService(sid);
        });
        this.events.subscribe('services-feature:saveService', (data, banner, sid) => {
            this.saveService(data, banner, sid);
        });
        this.events.subscribe('services-feature:submitResponse', (res, imgs) => {
            this.submitResponse(res, imgs);
        });
        this.events.subscribe('services-feature:getUserRequests', () => {
            this.getUserRequests();
        });
        this.events.subscribe('services-feature:completeRequest', (rid) => {
            this.completeRequest(rid);
        });
        this.events.subscribe('services-feature:getAllRequests', () => {
            this.getAllRequests();
        });
        this.setServicesInLocalStorage();
    }
    getServiceWithId(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const serviceData = yield this.afs.collection('services').doc(id).valueChanges().pipe(first()).toPromise();
                resolve(serviceData);
            }));
        });
    }
    getAllServices() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const services = yield this.afs.collection('services', ref => ref
                    .orderBy('createdAt', 'desc')).snapshotChanges().pipe(map((actions) => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                this.events.publish('services-feature:publishAllServices', services);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getAllServicesForHome() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const region = yield this.sharedService.checkRegionIdForApi();
                let regionId = region.regionId;
                let services;
                let servicesRef;
                if (regionId) {
                    servicesRef = this.afs.collection('services', ref => ref
                        .orderBy('createdAt', 'desc')
                        .where('regionId', 'array-contains', regionId));
                }
                else {
                    servicesRef = this.afs.collection('services', ref => ref
                        .orderBy('createdAt', 'desc'));
                }
                services = yield servicesRef.snapshotChanges().pipe(map((actions) => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                this.events.publish('services-feature:publishAllServicesForHome', services);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getServicesActiveStatus(route) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const status = yield this.afs.collection('features').doc('services').valueChanges().pipe(first()).toPromise();
                if (!route) {
                    this.events.publish('services-feature:publishServicesActiveStatus', status);
                }
                else {
                    return status;
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    changeServiceStatus(status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('features').doc('services').set({ isActive: status });
                this.events.publish('services-feature:statusChangeSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    deleteService(sid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.serviceRef.doc(sid).delete();
                this.events.publish('services-feature:getAllServices');
                this.events.publish('services-feature:deleteServiceSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    saveService(data, banner, sid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let baseImg = {};
                let storageImg = {};
                if (!sid) {
                    sid = this.afs.collection('services').ref.doc().id;
                }
                if (banner.length) {
                    banner.forEach(i => {
                        if (i.url.includes('data:image/jpeg;base64,')) {
                            baseImg = i;
                        }
                        else {
                            storageImg = i;
                        }
                    });
                }
                yield this.afs.collection('services').doc(sid).set({
                    name: data.name,
                    description: data.description,
                    imageMandatory: data.imageMandatory,
                    createdAt: data.createdAt,
                    banner: storageImg,
                });
                if (baseImg.hasOwnProperty('url')) {
                    const imgRef = this.fbStorage.ref(`services/${sid}/banner/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(baseImg.url, 'data_url');
                    const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                    yield this.afs.collection('services').doc(sid).update({ banner: { url: downloadURL } });
                }
                this.events.publish('services-feature:saveServiceSuccess');
                this.getAllServices();
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    submitResponse(res, imgs) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                res['userName'] = this.userService.getUserName();
                res['userPhone'] = this.userService.getPhoneNo();
                res['userId'] = this.userService.getUserId();
                const sid = this.afs.collection('serviceRequests').ref.doc().id;
                yield this.afs.collection('serviceRequests').doc(sid).set(res);
                if (imgs.length) {
                    for (const img of imgs) {
                        const imgRef = this.fbStorage.ref(`serviceRequests/${sid}/images/` + new Date().getTime().toString() + '.png');
                        yield imgRef.putString(img.url, 'data_url');
                    }
                }
                this.events.publish('services-feature:submitResponseSuccess');
                this.getUserRequests();
            }
            catch (error) {
                console.dir(error);
                this.events.publish('services-feature:submitResponseFailure');
            }
        });
    }
    getUserRequests() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const uid = yield this.getStorageUid();
            const requests = yield this.afs.collection('serviceRequests', ref => ref
                .orderBy('responseAt', 'desc')
                .where('userId', '==', uid))
                .snapshotChanges().pipe(map((actions) => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return Object.assign({ id }, data);
            }))).pipe(first()).toPromise();
            this.events.publish('services-feature:publishUserRequests', requests);
        });
    }
    getStorageUid() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                this.storage.get('uid').then((val) => {
                    resolve(val);
                });
            }));
        });
    }
    getAllRequests() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const requests = yield this.afs.collection('serviceRequests', ref => ref
                .orderBy('responseAt', 'desc'))
                .snapshotChanges().pipe(map((actions) => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return Object.assign({ id }, data);
            }))).pipe(first()).toPromise();
            this.events.publish('services-feature:publishAllRequests', requests);
        });
    }
    completeRequest(rid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('serviceRequests').doc(rid).update({ status: 'Completed' });
                this.events.publish('services-feature:completeRequestSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getServicesForMenu() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const storageServices = JSON.parse(localStorage.getItem('services')) || [];
                if (!storageServices.length) {
                    const services = yield this.setServicesInLocalStorage();
                    return services;
                }
                else {
                    return storageServices;
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    setServicesInLocalStorage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                const region = yield this.sharedService.checkRegionIdForApi();
                let regionId = region.regionId;
                let services;
                let servicesRef;
                if (regionId) {
                    servicesRef = this.afs.collection('services', ref => ref
                        .orderBy('createdAt', 'desc')
                        .where('regionId', 'array-contains', regionId));
                }
                else {
                    servicesRef = this.afs.collection('services', ref => ref
                        .orderBy('createdAt', 'desc'));
                }
                services = yield servicesRef.snapshotChanges().pipe(map((actions) => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                localStorage.setItem('services', JSON.stringify(services));
                resolve(services);
            }));
        });
    }
}
ServicesFeatureService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServicesFeatureService_Factory() { return new ServicesFeatureService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AngularFireStorage), i0.ɵɵinject(i4.Storage), i0.ɵɵinject(i5.UserService), i0.ɵɵinject(i6.SharedService)); }, token: ServicesFeatureService, providedIn: "root" });
