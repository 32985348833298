import * as tslib_1 from "tslib";
import { Events, NavController, LoadingController, AlertController } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import * as firebase from 'firebase';
import { Storage } from '@ionic/storage';
import { UserService } from '../user/user.service';
import { ConfigService } from '../config/config.service';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@ionic/storage";
import * as i4 from "../user/user.service";
import * as i5 from "../config/config.service";
export class WalletService {
    constructor(events, afs, storage, userService, navController, loadingController, alertController, configService) {
        this.events = events;
        this.afs = afs;
        this.storage = storage;
        this.userService = userService;
        this.navController = navController;
        this.loadingController = loadingController;
        this.alertController = alertController;
        this.configService = configService;
        this.transcations = [];
    }
    initializeSubscriptions() {
        this.events.subscribe('wallet:getWalletSettings', () => {
            this.getWalletSettings();
        });
        this.events.subscribe('wallet:saveWalletSettings', (data) => {
            this.saveWalletSettings(data);
        });
        this.events.subscribe('wallet:getCashbackList', () => {
            this.getCashbackList();
        });
        this.events.subscribe('wallet:addNewCashback', (data) => {
            this.addNewCashback(data);
        });
        this.events.subscribe('wallet:deleteCashback', (cid) => {
            this.deleteCashback(cid);
        });
        this.events.subscribe('wallet:addAmountToUsersByAdmin', (amount) => {
            this.addAmountToUsersByAdmin(amount);
        });
        this.events.subscribe('wallet:addMoneyToSingleUserWallet', (amount, uid) => {
            this.addMoneyToSingleUserWallet(amount, uid);
        });
        this.events.subscribe('wallet:getUserWalletDetails', (uid) => {
            this.getUserWalletDetails(uid);
        });
        this.events.subscribe('wallet:getWalletTrans', (uid) => {
            this.getWalletTrans(uid);
        });
        this.events.subscribe('wallet:getMoreWalletTrans', (uid) => {
            this.getMoreWalletTrans(uid);
        });
        this.events.subscribe('wallet:addMoneyWithRazorPay', (uid, amount, rid, mode, balance) => {
            this.addMoneyWithRazorPay(uid, amount, rid, mode, balance);
        });
        this.events.subscribe('wallet:addMoneyWithPaytm', (uid, amount, balance) => {
            this.addMoneyWithPaytm(uid, amount, balance);
        });
    }
    getWalletSettings(routeFrom) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const walletData = yield this.afs.collection('settings').doc('wallet').valueChanges().pipe(first()).toPromise();
                if (!routeFrom) {
                    this.events.publish('wallet:publishWalletSettings', walletData);
                }
                else {
                    return walletData;
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    saveWalletSettings(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('settings').doc('wallet').set(data);
                this.events.publish('wallet:saveWalletSettingsSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getCashbackList() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const cashbacksRef = this.afs.collection('settings').doc('wallet').collection('cashbacks', ref => ref.orderBy('createdAt', 'desc'));
                const cashbacksSnap = cashbacksRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                })));
                cashbacksSnap.subscribe((res) => {
                    this.events.publish('wallet:publishCashbackList', res);
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    addNewCashback(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const cashback = {
                    createdAt: new Date(),
                    orderAmount: parseInt(data.orderAmnt),
                    cashback: parseInt(data.cashback),
                    perUser: parseInt(data.perUser)
                };
                yield this.afs.collection('settings').doc('wallet').collection('cashbacks').add(cashback);
                this.events.publish('wallet:addNewCashbackSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    deleteCashback(cid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('settings').doc('wallet').collection('cashbacks').doc(cid).delete();
                this.events.publish('wallet:deleteCashbackSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    addAmountToUsersByAdmin(amount) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.storage.get('storeInfo').then((data) => {
                    const amntData = {
                        amount: amount,
                        storeName: data.storeName
                    };
                    let addAmount = firebase.functions().httpsCallable('wallet-addAmountToUsersByAdmin');
                    addAmount(amntData).then((res) => {
                        //// // console.log(res.data);
                        if (res.data.status) {
                            this.events.publish('wallet:addAmountToUsersByAdminSuccess', `<strong>Rs.${amount}</strong> has been added to all users wallet successfully`);
                        }
                        else {
                            this.events.publish('wallet:addAmountToUsersByAdminSuccess', 'There is some problem in adding money to all users wallet. Please try again later.');
                        }
                    });
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    addMoneyToSingleUserWallet(amount, uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.storage.get('storeInfo').then((data) => {
                    const amntData = {
                        amount: amount,
                        storeName: data.storeName,
                        uid: uid
                    };
                    let addMoney = firebase.functions().httpsCallable('wallet-addMoneyToSingleUserWallet');
                    addMoney(amntData).then((res) => {
                        //// // console.log(res.data);
                        if (res.data.status) {
                            this.events.publish('wallet:addMoneyToSingleUserWalletSuccess', `<strong>Rs.${amount}</strong> has been added to the user wallet successfully`);
                        }
                        else {
                            this.events.publish('wallet:addMoneyToSingleUserWalletSuccess', 'There is some problem in adding money to the user wallet. Please try again later.');
                        }
                    });
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getUserWalletDetails(uid, route) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (route && route == 'wallet') {
                    const userDetails = this.afs.collection('users').doc(uid).valueChanges().pipe(first()).toPromise();
                    return userDetails;
                }
                else {
                    this.afs.collection('users').doc(uid).valueChanges().subscribe((res) => {
                        //// // console.log(res);
                        this.events.publish('wallet:publishUserWalletDetails', res);
                    });
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getWalletTrans(uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.transcations = [];
                const txns = yield this.afs.collection('users').doc(uid).collection('walletTransactions', ref => ref
                    .orderBy('createdAt', 'desc')
                    .limit(this.configService.environment.scrollLimit)).snapshotChanges().pipe(first()).toPromise();
                if (txns.length > 0) {
                    this.lastTxn = txns[txns.length - 1].payload.doc;
                    for (const txn of txns) {
                        this.transcations.push(Object.assign({ id: txn.payload.doc.id }, txn.payload.doc.data()));
                    }
                }
                this.events.publish('wallet:publishWalletTrans', this.transcations);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getMoreWalletTrans(uid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const txns = yield this.afs.collection('users').doc(uid).collection('walletTransactions', ref => ref
                    .orderBy('createdAt', 'desc')
                    .limit(this.configService.environment.scrollLimit)
                    .startAfter(this.lastTxn)).snapshotChanges().pipe(first()).toPromise();
                if (txns.length > 0) {
                    this.lastTxn = txns[txns.length - 1].payload.doc;
                    for (const txn of txns) {
                        this.transcations.push(Object.assign({ id: txn.payload.doc.id }, txn.payload.doc.data()));
                    }
                }
                else {
                    this.events.publish('wallet:noMoreWalletTrans');
                }
                this.events.publish('wallet:publishWalletTrans', this.transcations);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    addMoneyWithRazorPay(uid, amount, razorpayId, method, balance) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.addMoneyWithRazorPayUsingCheckoutJs(uid, amount, razorpayId, method, balance);
        });
    }
    addMoneyWithRazorPayUsingCheckoutJs(uid, amount, razorpayId, method, balance) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.presentLoading();
                let createOrderInRazorpay = firebase.functions().httpsCallable('payments-razorpay_createOrder');
                const createOrderRes = yield createOrderInRazorpay({ amount: Math.round(amount * 100), orderDocId: '' });
                const razorpayOrderId = createOrderRes.data && createOrderRes.data.orderId ? createOrderRes.data.orderId : '';
                // console.log('razorpayOrderId', razorpayOrderId);
                if (razorpayOrderId) {
                    const storeInfo = yield this.storage.get('storeInfo');
                    const options = {
                        order_id: razorpayOrderId,
                        description: this.configService.environment.razorpay.description,
                        currency: this.configService.environment.razorpay.currency,
                        key: razorpayId,
                        amount: Math.round(amount * 100),
                        name: storeInfo.storeName ? storeInfo.storeName : '',
                        image: this.configService.environment.razorpay.image,
                        prefill: {
                            method: method,
                            contact: this.userService.getPhoneNo(),
                            name: this.userService.getUserName(),
                            email: this.userService.getUserEmail() || 'xyz@gmail.com',
                        },
                        theme: this.configService.environment.razorpay.theme,
                        modal: {
                            ondismiss: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                this.loading.dismiss();
                                this.presentFailureAlert();
                            })
                        },
                        handler: (handlerResponse) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            // console.log('handlerResponse', handlerResponse);
                            let verifySignature = firebase.functions().httpsCallable('payments-razorpay_verifySignature');
                            const apiBody = {
                                razorpay_payment_id: handlerResponse.razorpay_payment_id,
                                razorpay_order_id: handlerResponse.razorpay_order_id,
                                razorpay_signature: handlerResponse.razorpay_signature,
                            };
                            const verifySignatureRes = yield verifySignature(apiBody);
                            // console.log('verifySignatureRes', verifySignatureRes);
                            if (verifySignatureRes.data.signatureIsValid) {
                                const walletPaymentObj = {
                                    uid: uid,
                                    mode: 'razorpay',
                                    txnDetails: { paymentId: apiBody.razorpay_payment_id },
                                    amount: amount,
                                    balance: balance
                                };
                                const txnId = this.afs.collection('users').doc(uid).collection('walletTransactions').ref.doc().id;
                                walletPaymentObj['txnId'] = txnId;
                                let addMoneyToWalletByUser = firebase.functions().httpsCallable('wallet-addMoneyToWalletByUser');
                                addMoneyToWalletByUser(walletPaymentObj).then((res) => {
                                    //// // console.log(res.data);
                                    this.loading.dismiss();
                                    if (res.data.status && res.data.status === 'success') {
                                        this.presentAlert(`<strong>Rs.${amount}</strong> successfully added to your wallet.`);
                                    }
                                    else {
                                        this.presentAlert('Payment failed. Any amount debited will be refunded in 4 - 5 working days.');
                                    }
                                });
                            }
                            else {
                                this.loading.dismiss();
                                this.presentFailureAlert();
                            }
                        }),
                    };
                    var razorpay = new Razorpay(options);
                    razorpay.open();
                    razorpay.on('payment.failed', (response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        // console.log('failure response', response);
                        this.loading.dismiss();
                        this.presentFailureAlert();
                    }));
                }
                else {
                    this.loading.dismiss();
                    this.presentFailureAlert();
                }
            }
            catch (error) {
                console.dir(error);
                this.events.publish('order:paymentFailure');
            }
        });
    }
    addMoneyWithPaytm(uid, amount, balance) {
        const mobileNo = this.userService.getPhoneNo().slice(3);
        //// // console.log(typeof mobileNo, mobileNo);
        const txnId = this.afs.collection('users').doc(uid).collection('walletTransactions').ref.doc().id;
        let getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
        getCheckSum({
            orderId: txnId,
            customerId: uid,
            phoneNo: mobileNo,
            txnAmount: amount.toString(),
        }).then((result) => {
            //// // console.log('checksum:', result.data.checksum);
            const paytmParams = {
                MID: result.data.mid,
                ORDER_ID: txnId,
                CUST_ID: uid,
                CHANNEL_ID: this.configService.environment.paytm.CHANNEL_ID,
                TXN_AMOUNT: amount.toString(),
                WEBSITE: this.configService.environment.paytm.WEBSITE,
                CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + txnId,
                INDUSTRY_TYPE_ID: this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                MOBILE_NO: mobileNo,
                CHECKSUMHASH: result.data.checksum,
                ENVIRONMENT: this.configService.environment.paytm.ENVIRONMENT
            };
            let successCallback = (response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (response.STATUS == "TXN_SUCCESS") {
                    //// // console.log('response from paytm', response);
                    const walletPaymentObj = {
                        uid: uid,
                        mode: 'paytm',
                        txnDetails: response,
                        amount: amount,
                        balance: balance,
                        txnId: txnId
                    };
                    yield this.presentLoading();
                    let addMoneyToWalletByUser = firebase.functions().httpsCallable('wallet-addMoneyToWalletByUser');
                    addMoneyToWalletByUser(walletPaymentObj).then((res) => {
                        //// // console.log(res.data);
                        if (res.data.status && res.data.status === 'success') {
                            this.loading.dismiss();
                            this.presentAlert(`<strong>Rs.${amount}</strong> successfully added to your wallet.`);
                        }
                        else {
                            this.loading.dismiss();
                            this.presentAlert('Payment failed. Any amount debited will be refunded in 4 - 5 working days.');
                        }
                    });
                }
                else {
                    this.presentFailureAlert();
                }
            });
            let failureCallback = (error) => {
                this.presentFailureAlert();
            };
            paytm.startPayment(paytmParams, successCallback, failureCallback);
        }).catch(function (error) {
            var code = error.code;
            var message = error.message;
            var details = error.details;
            //// // console.log("Error", code, message, details);
            this.presentFailureAlert();
        });
    }
    createOrderCashfree(orderData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const orderObj = {
                        orderAmnt: parseFloat(orderData.amount.toFixed(2)),
                        userId: orderData.uid,
                        source: 'website-v2',
                        currencyCode: this.configService.environment.currencyCode,
                        user: {
                            id: orderData.uid,
                            email: this.userService.getUserEmail() || 'xyz@gmail.com',
                            phoneNo: this.userService.getPhoneNo()
                        }
                    };
                    let createOrderCashfree = firebase.functions().httpsCallable('payments-createOrderCashfree');
                    createOrderCashfree(orderObj).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (res.data) {
                            const cashfreeToken = res.data.order_token;
                            // this.loading.dismiss();
                            resolve(cashfreeToken);
                        }
                        else {
                            // this.loading.dismiss();
                            this.presentFailureAlert();
                            resolve({});
                        }
                    })).catch((error) => {
                        // this.loading.dismiss();
                        this.presentFailureAlert();
                        resolve({});
                        // console.log('err from callable fn: ',error);
                    });
                }
                catch (error) {
                    // this.loading.dismiss();
                    this.presentFailureAlert();
                    resolve({});
                    // console.log('err from catch:',error);
                }
            }));
        });
    }
    getOrderDetailsCashfree(cashfreeOrderId, orderData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.presentLoading();
                let obj = {
                    cashfreeOrderId: cashfreeOrderId
                };
                let getOrderDetailsCashfree = firebase.functions().httpsCallable('payments-getOrderDetailsCashfree');
                getOrderDetailsCashfree(obj).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    // console.log('res:', res);
                    if (res.data) {
                        if (res.data.order_status == 'PAID') {
                            let txnRes = {
                                order_status: res.data.order_status,
                                cf_order_id: res.data.cf_order_id,
                                created_at: res.data.created_at,
                                order_id: res.data.order_id,
                                order_amount: res.data.order_amount,
                            };
                            const walletPaymentObj = {
                                uid: orderData.uid,
                                mode: 'cashfree',
                                txnDetails: txnRes,
                                amount: orderData.amount,
                                balance: orderData.balance
                            };
                            const txnId = yield this.afs.collection('users').doc(orderData.uid).collection('walletTransactions').ref.doc().id;
                            walletPaymentObj['txnId'] = txnId;
                            let addMoneyToWalletByUser = firebase.functions().httpsCallable('wallet-addMoneyToWalletByUser');
                            addMoneyToWalletByUser(walletPaymentObj).then((res) => {
                                //// // console.log(res.data);
                                this.loading.dismiss();
                                if (res.data.status && res.data.status === 'success') {
                                    this.presentAlert(`<strong>Rs.${orderData.amount}</strong> successfully added to your wallet.`);
                                }
                                else {
                                    this.presentAlert('Payment failed. Any amount debited will be refunded in 4 - 5 working days.');
                                }
                            });
                        }
                        else {
                            this.loading.dismiss();
                            this.presentFailureAlert();
                            // console.log('not paid');
                        }
                    }
                    else {
                        this.loading.dismiss();
                        this.presentFailureAlert();
                    }
                })).catch((error) => {
                    this.loading.dismiss();
                    // console.log('err:::::::::::',error);
                });
            }
            catch (error) {
                this.loading.dismiss();
                // console.log('error:', error);
            }
        });
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: 'Please Wait...',
            });
            yield this.loading.present();
        });
    }
    presentAlert(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: msg,
                backdropDismiss: false,
                buttons: [{
                        text: 'Ok',
                        handler: () => {
                            this.navController.navigateRoot(['user-wallet']);
                        }
                    }]
            });
            yield alert.present();
        });
    }
    presentFailureAlert() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: 'Payment is failed! Please try again.',
                buttons: ['Try Again']
            });
            yield alert.present();
        });
    }
}
WalletService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WalletService_Factory() { return new WalletService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.Storage), i0.ɵɵinject(i4.UserService), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i5.ConfigService)); }, token: WalletService, providedIn: "root" });
