import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "@angular/fire/storage";
export class OfferService {
    constructor(events, afs, fbStorage) {
        this.events = events;
        this.afs = afs;
        this.fbStorage = fbStorage;
    }
    initializeSubscriptions() {
        this.events.subscribe('offer:saveOffer', (data, images, oid) => {
            this.saveOffer(data, images, oid);
        });
        this.events.subscribe('offer:getOffers', () => {
            this.getOffers();
            //// // console.log('service offer:getOffers');
        });
        this.events.subscribe('offer:deleteOffer', (oid) => {
            this.deleteOffer(oid);
        });
    }
    saveOffer(data, images, oid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let offerId = oid;
                //// // console.log('images', images);
                let base64Imgs = [];
                let storageUrlImgs = [];
                if (!oid) {
                    offerId = this.afs.collection('offers').ref.doc().id;
                }
                if (images.length) {
                    for (let index = 0; index < images.length; index++) {
                        if (images[index].url.includes('data:image/jpeg;base64,')) {
                            base64Imgs.push(images[index]);
                        }
                        else {
                            storageUrlImgs.push(images[index]);
                        }
                    }
                }
                //// // console.log('base64Imgs', base64Imgs);
                //// // console.log('storageUrlImgs', storageUrlImgs);
                yield this.afs.collection('offers').doc(offerId).set({
                    name: data.name,
                    description: data.description,
                    images: storageUrlImgs,
                    sortedAt: data.sortedAt
                });
                base64Imgs.forEach((img) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    const imgRef = this.fbStorage.ref(`offers/${offerId}/images/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(img.url, 'data_url');
                }));
                this.events.publish('offer:saveOfferSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getOffers() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //// // console.log('service getOffers')
            try {
                const offersRef = this.afs.collection('offers', ref => ref.orderBy('sortedAt', 'desc'));
                const offersSnap = offersRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                })));
                offersSnap.subscribe((res) => {
                    this.events.publish('offer:publishOffers', res);
                    //// // console.log('offers', res);
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    updateOffersPosition(id, changedDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //// // console.log('id & cdate', id, changedDate);
            yield this.afs.doc(`offers/${id}`).update({ sortedAt: changedDate });
            this.events.publish('offer:updateOffersPostionSucess');
        });
    }
    deleteOffer(oid) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('offers').doc(oid).delete();
                this.events.publish('offer:deleteOfferSucess');
            }
            catch (error) {
            }
        });
    }
}
OfferService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OfferService_Factory() { return new OfferService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AngularFireStorage)); }, token: OfferService, providedIn: "root" });
