import * as i0 from "@angular/core";
export class ProductUtilsService {
    constructor() { }
    getBookingDuration(product) {
        let duration = null;
        if (product.scheduleData && product.scheduleData.duration) {
            let months = this.getDurationKeyString('month', product.scheduleData.duration.months);
            let days = this.getDurationKeyString('day', product.scheduleData.duration.days);
            let hours = this.getDurationKeyString('hr', product.scheduleData.duration.hours);
            let minutes = this.getDurationKeyString('min', product.scheduleData.duration.minutes);
            duration = `${months} ${days} ${hours} ${minutes}`;
        }
        return duration;
    }
    getDurationKeyString(key, value) {
        return value ? value > 1 ? `${value} ${key}s` : `${value} ${key}` : '';
    }
}
ProductUtilsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductUtilsService_Factory() { return new ProductUtilsService(); }, token: ProductUtilsService, providedIn: "root" });
