import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import { convertSnaps } from '../db-utils';
import { SharedService } from '../shared/shared.service';
import { VendorService } from '../vendor/vendor.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "../shared/shared.service";
import * as i3 from "../vendor/vendor.service";
export class AppointmentService {
    constructor(afs, sharedService, vendorService) {
        this.afs = afs;
        this.sharedService = sharedService;
        this.vendorService = vendorService;
    }
    bookAppointment(data, slotLimit) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    //check slot availability
                    let slotAvailable = false;
                    let appointments = [];
                    if ('variant' in data.item) {
                        appointments = yield this.afs.collection('appointments', ref => ref
                            .where('item.id', '==', data.item.id)
                            .where('item.variant', '==', data.item.variant)
                            .where('schedule.date', '==', data.schedule.date)
                            .where('schedule.slot.start', '==', data.schedule.slot.start)
                            .where('schedule.slot.end', '==', data.schedule.slot.end)
                            .where('status', '==', 'accepted'))
                            .valueChanges().pipe(first()).toPromise();
                    }
                    else {
                        appointments = yield this.afs.collection('appointments', ref => ref
                            .where('item.id', '==', data.item.id)
                            .where('schedule.date', '==', data.schedule.date)
                            .where('schedule.slot.start', '==', data.schedule.slot.start)
                            .where('schedule.slot.end', '==', data.schedule.slot.end)
                            .where('status', '==', 'accepted'))
                            .valueChanges().pipe(first()).toPromise();
                    }
                    // console.log('appointments', appointments);
                    if (appointments.length < slotLimit) {
                        if (this.vendorService.vendorSettings.isMultiVendorAvailable) {
                            if (data.vendor.id) {
                                const vendorDetails = yield this.vendorService.getVendorData(data.vendor.id);
                                if (vendorDetails && 'appointmentSlotLimit' in vendorDetails && vendorDetails.appointmentSlotLimit) {
                                    const vendorAppointments = yield this.afs.collection('appointments', ref => ref
                                        .where('vendor.id', '==', data.vendor.id)
                                        .where('schedule.date', '==', data.schedule.date)
                                        .where('schedule.slot.start', '==', data.schedule.slot.start)
                                        .where('schedule.slot.end', '==', data.schedule.slot.end)
                                        .where('status', '==', 'accepted'))
                                        .valueChanges().pipe(first()).toPromise();
                                    if (vendorAppointments.length < vendorDetails.appointmentSlotLimit) {
                                        slotAvailable = true;
                                    }
                                    else {
                                        slotAvailable = false;
                                    }
                                }
                                else {
                                    slotAvailable = true;
                                }
                            }
                            else {
                                slotAvailable = true;
                            }
                        }
                        else {
                            slotAvailable = true;
                        }
                    }
                    else {
                        slotAvailable = false;
                    }
                    if (slotAvailable) {
                        yield this.afs.collection('appointments').add(data);
                        resolve('added');
                    }
                    else {
                        resolve('not_available');
                    }
                }
                catch (error) {
                    // console.log(error);
                    resolve('error');
                }
            }));
        });
    }
    getUserAppointments() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const userId = yield this.sharedService.getStorageUid();
                    const appointments = yield this.afs.collection('appointments', ref => ref
                        .orderBy('createdAt', 'desc')
                        .where('user.id', '==', userId))
                        .snapshotChanges()
                        .pipe(map(snaps => convertSnaps(snaps)))
                        .pipe(first()).toPromise();
                    resolve(appointments);
                }
                catch (error) {
                    // console.log(error);
                    resolve([]);
                }
            }));
        });
    }
}
AppointmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppointmentService_Factory() { return new AppointmentService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.SharedService), i0.ɵɵinject(i3.VendorService)); }, token: AppointmentService, providedIn: "root" });
