import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LabelService } from 'src/app/services/label/label.service';
import { Events } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ModalController } from '@ionic/angular';
export class CustomPaymentOptionPage {
    constructor(labelService, events, sharedService, modalController) {
        this.labelService = labelService;
        this.events = events;
        this.sharedService = sharedService;
        this.modalController = modalController;
        this.userScreenShot = '';
        this.textDetails = '';
        this.successRoute = 'tabs/tabs/user-order-history';
        this.pageType = 'page';
    }
    ngOnInit() {
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        this.CUSTOM_PAYMENT_OPTION_LABELS = this.labelService.labels['CUSTOM_PAYMENT_OPTION'];
        this.headerText = this.customOption.name;
        if (this.customOption.successRoute) {
            this.successRoute = this.customOption.successRoute;
        }
    }
    uploadPaymentSS(files) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            for (let i = 0; i < files.length; i++) {
                this.userScreenShot = yield this.readBase64(files.item(i));
                // console.log('this.userScreenShot', this.userScreenShot);
            }
        });
    }
    readBase64(file) {
        return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                resolve(event.target.result);
            };
        }));
    }
    removeSS() {
        this.userScreenShot = '';
    }
    completePayment() {
        if (this.customOption.textMandatory && !this.textDetails) {
            this.sharedService.presentAlert(this.CUSTOM_PAYMENT_OPTION_LABELS['add_text_alert']);
            return;
        }
        if (this.customOption.imageMandatory && !this.userScreenShot) {
            this.sharedService.presentAlert(this.CUSTOM_PAYMENT_OPTION_LABELS['upload_img_alert']);
            return;
        }
        const data = { textDetails: this.textDetails, image: this.userScreenShot, optionName: this.customOption.name };
        if (this.pageType == 'modal') {
            this.modalController.dismiss({ closed: false, response: data });
        }
        else {
            this.events.publish('order:ac_completeCustomOptionPayment', this.order, data);
        }
    }
    closeModal() {
        this.modalController.dismiss({ closed: true });
    }
}
