import * as tslib_1 from "tslib";
import { Events } from '@ionic/angular';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@angular/fire/firestore";
export class VariantsService {
    constructor(events, afs) {
        this.events = events;
        this.afs = afs;
    }
    initializeSubscriptions() {
        this.events.subscribe('variants:getAllColors', () => {
            this.getAllColors();
        });
        this.events.subscribe('variants:deleteColor', (cid, i) => {
            this.deleteColor(cid, i);
        });
        this.events.subscribe('variants:getVariantsTypeData', () => {
            this.getVariantsTypeData();
        });
        this.events.subscribe('variants:getAllTemplates', () => {
            this.getAllTemplates();
        });
    }
    getAllColors() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const colorsData = yield this.afs.collection('variants').doc('colors').collection('options', ref => ref
                    .orderBy('createdAt', 'desc')).snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                this.events.publish('variants:publishAllColors', colorsData);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    deleteColor(cid, i) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('variants').doc('colors').collection('options').doc(cid).delete();
                this.events.publish('variants:deleteColorSuccess', i);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getVariantsTypeData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let attributes = [];
                this.afs.collection('variants').doc('types').valueChanges().subscribe((data) => {
                    for (let index = 0; index < data.attributes.length; index++) {
                        if (data.attributes[index].active) {
                            attributes.push(data.attributes[index]);
                        }
                    }
                    this.events.publish('variants:publishVariantsTypeData', attributes);
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getAllTemplates() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const templatesData = yield this.afs.collection('variants').doc('templates').collection('options')
                    .snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                this.events.publish('variants:publishAllTemplates', templatesData);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
}
VariantsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VariantsService_Factory() { return new VariantsService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.AngularFirestore)); }, token: VariantsService, providedIn: "root" });
