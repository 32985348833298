import * as tslib_1 from "tslib";
import { AngularFirestore } from '@angular/fire/firestore';
import { Events } from '@ionic/angular';
import { AngularFireStorage } from '@angular/fire/storage';
import { first } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@ionic/angular";
import * as i3 from "@angular/fire/storage";
import * as i4 from "@ionic/storage";
export class AdminSettingsService {
    constructor(afs, events, fbStorage, storage) {
        this.afs = afs;
        this.events = events;
        this.fbStorage = fbStorage;
        this.storage = storage;
    }
    initializeSubscriptions() {
        //('admin service initializeSubscriptions')
        this.events.subscribe('admin-settings:saveSettingsData', (data, authSign, shopLogo) => {
            this.saveSettingsData(data, authSign, shopLogo);
        });
        this.events.subscribe('admin-settings:getSettingsData', () => {
            this.getSettingsData();
        });
        this.events.subscribe('admin-settings:getInvoiceData', () => {
            this.getInvoiceData();
        });
        this.events.subscribe('admin-settings:getStatesData', () => {
            this.getStatesData();
        });
        this.events.subscribe('admin-settings:savePaymentSettingsData', (data) => {
            this.savePaymentSettingsData(data);
        });
        this.events.subscribe('admin-settings:getPaytmData', () => {
            this.getPaytmData();
        });
        this.events.subscribe('admin-settings:getRazorPayData', () => {
            this.getRazorPayData();
        });
        this.events.subscribe('admin-settings:getPaymentInfoData', () => {
            this.getPaymentInfoData();
        });
        this.events.subscribe('admin-settings:saveTermsAndPrivacy', (data) => {
            this.saveTermsAndPrivacy(data);
        });
        this.events.subscribe('admin-settings:getTermsAndPrivacyData', () => {
            //// // console.log('service subscribe admin-settings:getTermsAndPrivacyData')
            this.getTermsAndPrivacyData();
        });
        this.events.subscribe('admin-settings:getStoreInfo', () => {
            this.getStoreInfo();
        });
        this.events.subscribe('admin-settings:getPlanDetails', () => {
            this.getPlanDetails();
        });
        this.events.subscribe('admin-settings:getSubscribedStoreInfo', () => {
            this.getSubscribedStoreInfo();
        });
        this.events.subscribe('admin-settings:getWebsiteSEOData', () => {
            this.getSEOData();
        });
        this.getAppInfo();
        this.getStoreInfo;
    }
    getSEOData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // // console.log('service getSEOData')
            try {
                const seoData = yield this.afs.collection('settings').doc('seo').valueChanges().pipe(first()).toPromise();
                //// // console.log(seoData);
                if (seoData) {
                    this.events.publish('admin-settings:publishSEOData', seoData);
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    saveSettingsData(data, authSign, shopLogo) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const settingsRef = this.afs.collection('settings').doc('store');
                const invoiceRef = this.afs.collection('settings').doc('invoice');
                settingsRef.get().subscribe((doc) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (doc.exists) {
                        yield settingsRef.update(data);
                    }
                    else {
                        yield settingsRef.set(data);
                    }
                }));
                const invoice = {
                    shopLogo: shopLogo,
                    signature: authSign
                };
                invoiceRef.get().subscribe((doc) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (doc.exists) {
                        yield invoiceRef.update(invoice);
                    }
                    else {
                        yield invoiceRef.set(invoice);
                    }
                }));
                this.events.publish('admin-settings:saveSettingsDataSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getSettingsData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const settingsData = yield this.afs.collection('settings').doc('store').valueChanges().pipe(first()).toPromise();
                    // console.log(settingsData);
                    resolve(settingsData);
                }
                catch (error) {
                    console.dir(error);
                }
            }));
        });
    }
    getInvoiceData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const invoiceData = yield this.afs.collection('settings').doc('invoice').valueChanges().pipe(first()).toPromise();
                //// // console.log(invoiceData);
                this.events.publish('admin-settings:publishInvoiceData', invoiceData);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getStatesData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const statesData = yield this.afs.collection('settings').doc('states').valueChanges().pipe(first()).toPromise();
                //// // console.log(statesData);
                this.events.publish('admin-settings:publishStatesData', statesData.codes);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    savePaymentSettingsData(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const paytmRef = this.afs.collection('payment').doc('paytm');
                const razorpayRef = this.afs.collection('payment').doc('razorpay');
                const infoRef = this.afs.collection('payment').doc('info');
                yield paytmRef.set({
                    active: data.paytmActive,
                    merchantId: data.paytmMerchantId,
                    key: data.paytmKey
                });
                yield razorpayRef.set({
                    active: data.razorpayActive,
                    id: data.razorpayId,
                    keySecret: data.razorpayKeySecret
                });
                yield infoRef.set({
                    autoConfirmOrder: data.autoConfirmOrder,
                    gstNo: data.gstNo,
                    defaultGst: data.defaultGst,
                    minOrderAmount: data.minOrderAmount,
                    maxOrderAmount: data.maxOrderAmount,
                    isPackagingCharges: data.isPackagingCharges,
                    packaging: {
                        label: data.packaging.label,
                        price: data.packaging.price
                    },
                    isGstApplicable: data.isGstApplicable,
                    panNo: data.panNo,
                    isCod: data.isCod
                });
                this.events.publish('admin-settings:savePaymentSettingsDataSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getPaytmData(callType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const paytmData = yield this.afs.collection('payment').doc('paytm').valueChanges().pipe(first()).toPromise();
                if (callType && callType === 'return') {
                    return paytmData;
                }
                else {
                    this.events.publish('admin-settings:publishPaytmData', paytmData);
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getRazorPayData(callType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const razorpayData = yield this.afs.collection('payment').doc('razorpay').valueChanges().pipe(first()).toPromise();
                if (callType && callType === 'return') {
                    return razorpayData;
                }
                else {
                    this.events.publish('admin-settings:publishRazorPayData', razorpayData);
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getPaymentInfoData(callType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const infoData = yield this.afs.collection('payment').doc('info').valueChanges().pipe(first()).toPromise();
                if (callType && callType === 'return') {
                    return infoData;
                }
                else {
                    this.events.publish('admin-settings:publishPaymentInfoData', infoData);
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    saveTermsAndPrivacy(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const settingsRef = this.afs.collection('settings').doc('policies');
                settingsRef.get().subscribe((doc) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (doc.exists) {
                        yield settingsRef.update({
                            terms: data.terms,
                            privacy: data.privacy
                        });
                    }
                    else {
                        yield settingsRef.set({
                            terms: data.terms,
                            privacy: data.privacy
                        });
                    }
                }));
                this.events.publish('admin-settings:saveTermsAndPrivacySuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getTermsAndPrivacyData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            // // console.log('getTermsAndPrivacyData');
            try {
                const policiesData = yield this.afs.collection('settings').doc('policies').valueChanges().pipe(first()).toPromise();
                //// // console.log('getTermsAndPrivacyData', policiesData);
                this.storage.set('policiesData', policiesData).then((result) => {
                    //// // console.log('policiesData set to storage')
                    this.events.publish('admin-settings:publishTermsAndPrivacyData');
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getPlanDetails() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.afs.collection('settings').doc('plan').valueChanges()
                    .subscribe((res) => {
                    this.events.publish('admin-settings:publishPlanDetails', res);
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getStoreInfo(callType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const storeInfo = yield this.afs.collection('settings').doc('store').valueChanges().pipe(first()).toPromise();
                this.storage.set('storeInfo', storeInfo);
                if (callType === 'return') {
                    return storeInfo;
                }
                else {
                    this.events.publish('admin-settings:publishStoreInfo');
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getStoreInfoDataInSessionStorage() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                let data = JSON.parse(sessionStorage.getItem("storeInfoData")) || {};
                if (!(Object.keys(data).length)) {
                    data = yield this.afs.collection('settings').doc('store').valueChanges().pipe(first()).toPromise();
                    sessionStorage.setItem("storeInfoData", JSON.stringify(data));
                }
                resolve(data);
            }));
        });
    }
    getSubscribedStoreInfo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.afs.collection('settings').doc('store')
                    .valueChanges()
                    .subscribe((response) => {
                    this.storage.set('storeInfo', response);
                    this.events.publish('admin-settings:storeInfo', response);
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getAppInfo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.afs.collection('settings').doc('app').valueChanges()
                    .subscribe((res) => {
                    this.storage.set('appInfo', res).then((result) => {
                        this.events.publish('admin-settings:publishAppInfo');
                    });
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getStripeData() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const stripeData = yield this.afs.collection('payment').doc('stripe').valueChanges().pipe(first()).toPromise();
                return stripeData && Object.keys(stripeData).length ? stripeData : { active: false };
            }
            catch (error) {
                console.dir(error);
                return { active: false };
            }
        });
    }
    getPaymentGateway(gatewayChoice) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const gatewayData = yield this.afs.collection('payment').doc(gatewayChoice).valueChanges().pipe(first()).toPromise();
                return gatewayData && Object.keys(gatewayData).length ? gatewayData : { active: false };
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getSettingsWithDocId(docId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    const settingsData = yield this.afs.collection('settings').doc(docId).valueChanges().pipe(first()).toPromise();
                    resolve(settingsData || null);
                }
                catch (error) {
                    console.dir(error);
                    resolve(null);
                }
            }));
        });
    }
}
AdminSettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminSettingsService_Factory() { return new AdminSettingsService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.Events), i0.ɵɵinject(i3.AngularFireStorage), i0.ɵɵinject(i4.Storage)); }, token: AdminSettingsService, providedIn: "root" });
